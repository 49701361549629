import styled from 'styled-components';

export const DropZoneCustomStyled = styled.div`
  .dropZone-container {
    position: relative;
    .file-preview {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 100;
    }
    .file-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
