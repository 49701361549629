import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const HomePageStyled = styled.div`
  .most-popular-item {
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ddd;
    button {
      height: fit-content;
      min-width: 50px;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .mt-4 {
    margin-top: 4px;
  }

  .blockify-helper-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .blockify-helper-center {
      width: 100%;
      .Polaris-Box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .w-100 {
          width: 100%;
        }
      }
      .Polaris-Text--root {
        text-align: center;
      }
    }
    .border-right {
      border-right: 1px solid #ddd;
    }

    .border-bottom {
      padding-bottom: 8px;
      border-bottom: 2px solid #ddd;
    }
    .blockify-helper-center-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 8px;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
      img {
        margin-top: 8px;
        width: 50px;
        height: 50px;
      }
    }
  }
  .padding-custom {
    .Polaris-Box {
      padding: 0px;
    }
  }
  .Polaris-Page > .Polaris-Box {
    padding-bottom: 8px;
  }
  .inline-grid-br-12 {
    .Polaris-InlineGrid {
      overflow: hidden;
    }
  }
  .card-convert-free {
    margin-top: 1rem;
    .Polaris-CalloutCard {
      flex-direction: row-reverse;
      .Polaris-CalloutCard__Image {
        width: 10rem;
      }
    }
  }

  .card-info {
    margin-top: 16px;
    .Polaris-Icon {
      margin: 0;
    }
    .card-text {
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      padding: 4px;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
    }
  }

  .banner-refer-app-content {
    align-items: center;
    display: flex;
    .banner-refer-app-logo {
      border-radius: 8px;
      margin-right: 8px;
      width: 30px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
    .btn-set-up {
      min-width: 15%;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    .banner-refer-app-content {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
    .btn-set-up {
      margin-left: 42px;
    }
  }
`;
