import React, { useMemo, useRef, useState } from 'react';
import { ModalSearchStyled, RenderItemSearchStyled } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { handleIdScrollIntoView, handleShowModalSearch, showModalSearchSelector } from '@/redux/slice/home.slice';
import { BlockStack, Icon, Text, TextField } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';

import { extractWords } from '@/helpers';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';
import { images } from '@/asset';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/constants';
import { handleTabTotalVisitors } from '@/redux/slice/visitorAnalytics.slice';
import { ActionType, CriteriaType, IDScrollIntoView, ListType, OptionSettings, TabTotalVisitors } from '@/constants/enum';
import blockListSlice from '@/redux/slice/blockList.slice';
import { initSettingsBlockPage } from '@/pages/welcome/components/StepThree';
import slice from '@/redux/slice';

type SettingsListType = {
  onClick: () => void;
  steps: string[];
};

const ModalSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showModalSearch = useSelector(showModalSearchSelector);

  const settingsList: SettingsListType[] = useMemo(
    () => [
      {
        steps: ['Visitor analytics', 'Allowed visitors'],
        onClick: () => {
          dispatch(handleTabTotalVisitors(TabTotalVisitors.AllowedVisitor));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_block_visitor));
          navigate(PATH.ANALYTICS);
        },
      },
      {
        steps: ['Visitor analytics', 'Blocked visitors'],
        onClick: () => {
          dispatch(handleTabTotalVisitors(TabTotalVisitors.BlockedVisitor));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_block_visitor));
          navigate(PATH.ANALYTICS);
        },
      },
      {
        steps: ['Visitor analytics', 'Daily visit trends'],
        onClick: () => {
          navigate(PATH.ANALYTICS);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Daily_visit_trends));
        },
      },
      {
        steps: ['Block & redirector', 'Block IP address'],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettingsBlockPage,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          );
        },
      },
      {
        steps: ['Block & redirector', 'Block location'],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettingsBlockPage,
              criteria: CriteriaType.Country,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          );
        },
      },
      {
        steps: ['Block & redirector', 'Whitelist IP address'],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettingsBlockPage,
              criteria: CriteriaType.IpAddress,
              type: ActionType.WhiteList,
              priority: ListType.WhiteList,
            }),
          );
        },
      },
      {
        steps: ['Block & redirector', 'Whitelist location'],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettingsBlockPage,
              criteria: CriteriaType.Country,
              type: ActionType.WhiteList,
              priority: ListType.WhiteList,
            }),
          );
        },
      },
      {
        steps: ['Block & redirector', 'Redirect location'],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettingsBlockPage,
              criteria: CriteriaType.Country,
              type: ActionType.Redirect,
              priority: ListType.BlackList,
            }),
          );
        },
      },
      {
        steps: ['Fraud order analytics', 'Auto-block visitor placing fraud orders'],
        onClick: () => {
          navigate(PATH.FRAUD_ORDER);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_block_visitors_placing_fraud_orders));
        },
      },
      {
        steps: ['Fraud order analytics', 'Auto-cancel high risk orders'],
        onClick: () => {
          navigate(PATH.FRAUD_ORDER);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_cancel_high_risk_orders));
        },
      },
      {
        steps: ['Settings', 'Bot killer', 'Proxy and VPN blocker'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Proxy_and_vpn_blocker));
        },
      },
      {
        steps: ['Settings', 'Bot killer', 'Auto-block visitors'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_block_visitors));
        },
      },
      {
        steps: ['Settings', 'Bot killer', 'Tor blocker'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Tor_blocker));
        },
      },
      {
        steps: ['Settings', 'Bot killer', 'Allowed bot list'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_bot_list));
        },
      },
      {
        steps: ['Settings', 'Content protection', 'Protect content'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Protect_content));
        },
      },
      {
        steps: ['Settings', 'Content protection', 'Deactivate right click'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_right_click));
        },
      },
      {
        steps: ['Settings', 'Content protection', 'Deactivate copy shortcut'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_copy_shortcut));
        },
      },
      {
        steps: ['Settings', 'Content protection', 'Deactivate inspect'],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_inspect));
        },
      },
      {
        steps: ['Settings', 'Blocking template', 'Edit template'],
        onClick: () => {
          navigate(PATH.CUSTOMTEMPLATE);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Edit_template));
        },
      },
    ],
    [dispatch, navigate],
  );

  const [settingsListForSearch, setSettingsListForSearch] = useState(settingsList);
  const [searchValue, setSearchValue] = useState<string>('');

  const modalSearchRef = useRef(null);

  const onCloseModalSearch = () => {
    dispatch(handleShowModalSearch(false));
    setSearchValue('');
    setSettingsListForSearch(settingsList);
  };

  useOnClickOutside(modalSearchRef, () => {
    if (!showModalSearch) return;
    onCloseModalSearch();
  });

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape' && showModalSearch) {
      onCloseModalSearch();
    }
  });

  const handleOnChangeSearch = (value: string) => {
    setSearchValue(value);

    const words = extractWords(value);

    if (words.length === 0) {
      setSettingsListForSearch(settingsList);
      return;
    }

    const newListForSearch = settingsList
      .filter((setting) => setting.steps.some((step) => words.some((word) => step.toLowerCase().includes(word.toLowerCase()))))
      .sort((setting) => (setting.steps.some((step) => step.toLowerCase() === value.toLowerCase().trim()) ? -1 : 0));

    setSettingsListForSearch(newListForSearch);
  };

  const RenderItemSearch = ({ item, searchValue }: { item: SettingsListType; searchValue: string[] }) => {
    const highlightText = (step: string, highlights: string[]) => {
      if (!highlights || highlights.length === 0) return step;

      const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
      const parts = step.split(regex);

      return parts.map((part, index) =>
        highlights.some((highlight) => part.toLowerCase() === highlight.toLowerCase()) ? (
          <Text id="text-highlight" as="strong" key={index} variant="headingMd">
            {part}
          </Text>
        ) : (
          part
        ),
      );
    };

    const handleOnclick = (item: SettingsListType) => () => {
      item.onClick();
      onCloseModalSearch();
    };

    return (
      <RenderItemSearchStyled onClick={handleOnclick(item)}>
        {item.steps.map((step, index) => (
          <React.Fragment key={index}>
            <Text as="p">{highlightText(step, searchValue)}</Text>
            {index !== item.steps.length - 1 ? '>' : ''}
          </React.Fragment>
        ))}
      </RenderItemSearchStyled>
    );
  };

  return showModalSearch ? (
    <ModalSearchStyled>
      <div className="card-content" ref={modalSearchRef}>
        <TextField
          autoComplete=""
          label
          placeholder="Access settings (Shortcut: Ctrl + Shift + F)"
          prefix={<Icon source={SearchIcon} />}
          autoFocus
          value={searchValue}
          onChange={handleOnChangeSearch}
        />

        <div className="search-result">
          {settingsListForSearch.length === 0 ? (
            <BlockStack inlineAlign="center" gap="500">
              <img width="140px" src={images.illustration} alt="logo-check"></img>
              <Text as="h3" variant="headingLg">
                Sorry, we couldn’t find any matches 😔
              </Text>
              <Text as="p" tone="subdued" variant="bodyMd">
                Please adjust your search terms and try again!
              </Text>
            </BlockStack>
          ) : (
            settingsListForSearch.map((setting) => (
              <RenderItemSearch key={JSON.stringify(setting.steps)} item={setting} searchValue={extractWords(searchValue)} />
            ))
          )}
        </div>
      </div>
    </ModalSearchStyled>
  ) : null;
};

export default ModalSearch;
