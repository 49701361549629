import { BREAKPOINT } from '@/constants/enum';
import { Button, Card, Collapsible, InlineGrid } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BannerTemplateStyled } from './styled';
interface Props {
  src: string;
  children: React.ReactNode;
  onClick?: () => void;
  isOpen: boolean;
  onDismiss: () => void;
}
const BannerTemplate = ({ src, children, onClick, isOpen, onDismiss }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });

  return (
    <Collapsible id={'promote-card'} open={isOpen} transition={{ duration: '500ms' }}>
      <BannerTemplateStyled>
        <Card padding={'0'}>
          <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']}>
            <div className="banner-template-left">
              <img className="banner-img" src={src} alt="_blank" rel={'preload'} onClick={onClick} />
            </div>
            <div className={`banner-template-right ${isMobile ? 'pd-16' : 'pl-16 pd-16'}`}>{children}</div>
          </InlineGrid>
          <div className='btn-cancel'>
            <Button icon={XIcon} variant='plain' onClick={onDismiss} />
          </div>
        </Card>
      </BannerTemplateStyled>
    </Collapsible>
  );
};

export default memo(BannerTemplate);
