import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import BoldText from '@/components/BoldText';
import { CriteriaType } from '@/constants/enum';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { Banner, Button, Collapsible } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Page = () => {
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const { userPlanFree } = UserPlans();
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });
  const [checkScopePage, setCheckScopePage] = useState({
    status: false,
    url: '',
  });
  const [getSpecificPages, getSpecificPagesStatus] = apiCaller.useLazyGetSpecificPagesQuery();
  const [specificPages, setSpecificPages] = useState<Array<{ label: string; value: string }>>([]);
  useEffect(() => {
    const fetchData = () => {
      getSpecificPages({ search: '' }).then((res) => {
        if (res.data) {
          if (res.data.state === 2) {
            setCheckScopePage({
              status: true,
              url: res.data.urlUpdateScope || '',
            });
          } else {
            const list = res.data.listPagesAvailable?.map(({ title, id }) => {
              return {
                label: title,
                value: id.toString(),
              };
            });
            setSpecificPages(list);
          }
        }
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Collapsible id="block-specific-page-banner" open={checkScopePage.status} transition={{ duration: '500ms' }}>
        <Banner title="To use this feature, we need permission to read Online store’s content." tone="warning">
          <div className="mt-8">
            <Button url={checkScopePage.url} target="_blank" disabled={getSpecificPagesStatus.isLoading || userPlanFree}>
              Grant permission
            </Button>
          </div>
        </Banner>
      </Collapsible>
      <AutoCompleteWithTag
        helpText={!blockRuleSelected.id ? 'You can select multiple pages at the same time.' : undefined}
        disable={getSpecificPagesStatus.isLoading || userPlanFree}
        options={specificPages || []}
        label="Select pages"
        selectedOptions={blockRuleSelected.pageId || []}
        setSelectedOptions={(selected: string[]) => {
          if (selected.length === 0) {
            useError.handleErrorRule('Page must be added');
          } else if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          const pageTitle = selected.map((item) => {
            return specificPages?.find((page) => item === page.value)?.label || '';
          });
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              pageId: selected,
              pageTitle,
            }),
          );
        }}
        allowMultiple={!blockRuleSelected.id}
        requiredIndicator
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.pageId?.length === 0) {
            useError.handleErrorRule('Page must be added');
          }
        }}
      />

      <BoldText>From</BoldText>

      <AutoCompleteWithTag
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              country: value,
            }),
          );
        }}
        allowMultiple={false}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
        disable={userPlanFree}
      />
    </div>
  );
};

export default memo(Page);
