import BannerGrantPermission from '@/components/BannerGrantPermission';
import BannerLimitRules from '@/components/BannerLimitRules';
import BoldText from '@/components/BoldText';
import CustomDatePicker from '@/components/datePicker';
import HelpCenter from '@/components/HelpCenter';
import Layout from '@/components/layout';
import ProcessOldOrders from '@/components/ProcessOldOrders';
import RegularText from '@/components/RegularText';
import ScrollInToView from '@/components/ScrollInToView';
import SettingToggle from '@/components/settingToggle';
import { config } from '@/config';
import { Enum } from '@/constants';
import { BREAKPOINT, IDScrollIntoView, ScopeGroups, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, dateToTimeStamp, disablePlan, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector, titleBtnDatePickerSelector } from '@/redux/slice/fraudOrders';
import toastSlice from '@/redux/slice/toast.slice';
import { BlockStack, Button, Checkbox, Icon, InlineGrid, Link, RangeSlider, Text, Tooltip } from '@shopify/polaris';
import { InfoIcon, OrderIcon, RefreshIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Analytics from './Components/Analytics';
import Table from './Components/Table';
import { FraudOrdersStyled } from './styled';

const PrimaryAction = (): JSX.Element => {
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const getListOrder = apiCaller.useGetListOrderQuery(
    {
      endDate: dateToTimeStamp(fraudOrdersTable.endDate),
      startDate: dateToTimeStamp(fraudOrdersTable.startDate),
      ip: fraudOrdersTable.search,
      isVpn: fraudOrdersTable.vpn,
      page: fraudOrdersTable.page,
      perPage: Number(fraudOrdersTable.perPage),
      sortBy: fraudOrdersTable.sortBy,
      sortDirection: fraudOrdersTable.sort.toUpperCase(),
    },
    { skip: isSkip },
  );
  const getOrderSummary = apiCaller.useFraudOrdersSummaryQuery(
    {
      identifierId: config.shop,
      startDate: dateToTimeStamp(fraudOrdersTable.startDate),
      endDate: dateToTimeStamp(fraudOrdersTable.endDate),
    },
    { skip: isSkip },
  );
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      fraudOrdersSlice.actions.handleFraudOrdersTable({
        ...fraudOrdersTable,
        startDate,
        endDate,
      }),
    );
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(fraudOrdersSlice.actions.handleTitleBtnDatePicker(title));
  };
  const handleRefresh = () => {
    Promise.all([getListOrder.refetch(), getOrderSummary.refetch]);
  };
  return (
    <div className="d-flex mr-8">
      <Button loading={getListOrder.isFetching || getOrderSummary.isLoading} onClick={handleRefresh} icon={RefreshIcon}>
        Refresh
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={fraudOrdersTable.startDate}
          endDate={fraudOrdersTable.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          conditions={false}
        />
      </div>
      {dataSettings?.settings.user.orderScope ? (
        <div className="ml-8">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            icon={<Icon source={OrderIcon} tone="base" />}
          >
            Analyze old orders
          </Button>
          <ProcessOldOrders isOpen={isOpenModal} handleIsOpen={(value) => setIsOpenModal(value)} />
        </div>
      ) : null}
    </div>
  );
};

const FraudOrders = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const banner = useSelector(bannerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { userPlanFree, userPlanPremium, shopifyPlanPlus, userPlanEnterprise, userPlanShopifyPlus } = userPlans();
  const [activeFraudOrder, activeFraudOrderStatus] = apiCaller.useUpdateAutoCancelHighRiskOrderMutation();
  const [updateScope] = apiCaller.useLazyGetUrlUpdatePaymentScopeQuery();
  const { data } = apiCaller.useGetInfoAutoCancelHighRiskOrderQuery(undefined, { skip: isSkip });
  const [buttonClick, setButtonClick] = useState('');
  const [state, setState] = useState({
    autoBlockOrder: false,
    autoCancelOrder: false,
    reStock: true,
    buyer: true,
    merchant: true,
    refund: false,
    orderRiskScore: 0,
    cancelHighRisk: true,
    cancelMediumRisk: false,
    deleteImmediately: false,
  });
  const handleAutoBlockFraudOrders = useCallback(() => {
    const newAutoBlockOrderStatus = !data?.data.enabledAutoBlockFraudOrder;

    setState((prevState) => ({
      ...prevState,
      autoBlockOrder: newAutoBlockOrderStatus,
    }));

    activeFraudOrder({
      enabledAutoBlockFraudOrder: newAutoBlockOrderStatus,
      orderRiskScore: state.orderRiskScore,
      urlParams: config.urlParams,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('1');
  }, [activeFraudOrder, dispatch, state.orderRiskScore, data]);

  const handleAutoCancelFraudOrders = useCallback(() => {
    const newAutoCancelOrderStatus = !data?.data.enabledAutoCancelFraudOrder;
    const newReStockStatus = true;
    const newSendBuyerNoticeStatus = true;
    const newSendMerchantNoticeStatus = true;
    const newEnabledAutoRefundCancelledOrderStatus = false;

    setState((prevState) => ({
      ...prevState,
      autoCancelOrder: newAutoCancelOrderStatus,
      reStock: newReStockStatus,
      buyer: newSendBuyerNoticeStatus,
      merchant: newSendMerchantNoticeStatus,
      refund: newEnabledAutoRefundCancelledOrderStatus,
    }));

    activeFraudOrder({
      enabledAutoCancelFraudOrder: newAutoCancelOrderStatus,
      enabledRestock: newReStockStatus,
      enabledSendBuyerNotice: newSendBuyerNoticeStatus,
      enabledSendMerchantNotice: newSendMerchantNoticeStatus,
      enabledAutoRefundCancelledOrder: newEnabledAutoRefundCancelledOrderStatus,
      urlParams: config.urlParams,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('2');
  }, [activeFraudOrder, dispatch, data]);

  const handleChangeAutoCancelOrder = useCallback(
    (field: string) => (newChecked: boolean) => {
      const updatedData = {
        ...state,
        [field]: newChecked,
      };

      setState(updatedData);

      activeFraudOrder({
        enabledAutoCancelFraudOrder: updatedData.autoCancelOrder,
        enabledRestock: updatedData.reStock,
        enabledSendBuyerNotice: updatedData.buyer,
        enabledSendMerchantNotice: updatedData.merchant,
        enabledAutoRefundCancelledOrder: updatedData.refund,
        urlParams: config.urlParams,
        cancelHighRisk: updatedData.cancelHighRisk,
        cancelMediumRisk: updatedData.cancelMediumRisk,
        deleteImmediately: updatedData.deleteImmediately,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
      setButtonClick('3');
    },
    [activeFraudOrder, dispatch, state],
  );
  // eslint-disable-next-line
  const debouncedUpdateOrderRiskScore = useCallback(
    _debounce((value) => {
      activeFraudOrder({
        orderRiskScore: value,
        urlParams: config.urlParams,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }, 500),
    [activeFraudOrder, dispatch],
  );

  const handleRangeSliderChange = useCallback(
    (value: number) => {
      setState((prevState) => ({
        ...prevState,
        orderRiskScore: value,
      }));
      debouncedUpdateOrderRiskScore(value);
    },
    [debouncedUpdateOrderRiskScore],
  );

  // GRAND PERMISSION FOR BOTH READ & WRITE ORDERS
  const handleUpdateScope = () => {
    if (dataSettings) {
      updateScope({
        scopeGroups: ScopeGroups.processOrders,
      }).then((res) => {
        if (res.data?.url) {
          window.open(res.data.url, '_blank');
        }
      });
    }
  };

  useEffect(() => {
    if (data && data.data) {
      setState({
        autoBlockOrder: data.data.enabledAutoBlockFraudOrder,
        autoCancelOrder: data.data.enabledAutoCancelFraudOrder,
        reStock: data.data.enabledRestock,
        buyer: data.data.enabledSendBuyerNotice,
        merchant: data.data.enabledSendMerchantNotice,
        orderRiskScore: Number(data.data.orderRiskScore) || 0,
        refund: data.data.enabledAutoRefundCancelledOrder,
        cancelHighRisk: data.data.cancelHighRisk,
        cancelMediumRisk: data.data.cancelMediumRisk,
        deleteImmediately: data.data.deleteImmediately,
      });
    }
  }, [data]);

  return (
    <Layout
      layoutProps={{
        title: 'Fraud orders analytics',
        fullWidth: true,
        primaryAction: (
          <div className="d-flex">
            <HelpCenter />
          </div>
        ),
      }}
    >
      <FraudOrdersStyled>
        <BannerGrantPermission
          title="Important step to use this feature"
          isVisible={
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) ||
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
          }
          enum={ScopeGroups.processOrders}
          children={`To use this feature, we need permission to ${!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) &&
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
            ? 'view and edit'
            : 'edit'
            } your store orders data.`}
          variant="primary"
        />
        {banner.fraudOrderBanner && userPlanFree && (
          <div className="mb-16">
            <BannerLimitRules
              onDismiss={() => {
                dispatch(handleVisibleBanner({
                  key: 'fraudOrderBanner',
                  value: false
                }));
              }}
              mixpanelName="Fraud_order_increase_limit"
            />
          </div>
        )}
        <div className="mb-16">
          <PrimaryAction />
        </div>

        <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_block_visitors_placing_fraud_orders}>
          <SettingToggle
            trackAction="fraud_order_auto_block_visitors"
            minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
            title={
              isMobile ?
                <div className='d-flex'>Auto-block visitors
                  <Tooltip content="Auto-block visitors placing fraud orders">
                    <div className='ml-4'>
                      <Icon source={InfoIcon}></Icon>
                    </div>
                  </Tooltip>
                </div>
                :
                "Auto-block visitors placing fraud orders"
            }
            settingToggleProps={{
              enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.autoBlockOrder,
              action: {
                onAction: handleAutoBlockFraudOrders,
                loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '1',
              },
            }}
            mixpanelKey="Fraud_order_button"
            contentTooltipWithLink={
              dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
                ? undefined
                : 'Please grant permission to turn on this feature'
            }
            onClick={handleUpdateScope}
            disabled={
              !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) &&
              !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
            }
          >
            <RegularText>
              Turn on this feature so that when there is an order that Shopify detects as high risk of fraud, we will
              automatically add the IPs that created this high risk order to the blocked list.{' '}
              <Link target="_blank" url="https://docs.ipblocker.io/getting-started/fraud-orders">
                Learn about fraud orders.
              </Link>
              {state.autoBlockOrder &&
                (userPlanEnterprise || userPlanShopifyPlus) &&
                dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders) ? (
                <div className="mt-16 mb-16 range">
                  <RangeSlider
                    output
                    label={<RegularText>Fraud order score starts at</RegularText>}
                    value={state.orderRiskScore}
                    suffix={
                      <p
                        style={{
                          minWidth: '24px',
                          textAlign: 'right',
                        }}
                      >
                        {state.orderRiskScore}
                      </p>
                    }
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={handleRangeSliderChange}
                  />
                  <div className="risk-score">
                    ({state.orderRiskScore < 0.4 ? 'Low risk' : state.orderRiskScore < 0.7 ? 'Medium risk' : 'High risk'})
                  </div>
                </div>
              ) : null}
            </RegularText>
          </SettingToggle>
        </ScrollInToView>

        <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_cancel_high_risk_orders} className="mt-16">
          <SettingToggle
            trackAction="fraud_order_auto_cancel_high_risk_button"
            minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
            title={
              <BoldText>
                {
                  isMobile ?
                    <div className='d-flex'>Auto-cancel orders
                      <Tooltip content="Auto-cancel high risk orders">
                        <div className='ml-4'>
                          <Icon source={InfoIcon}></Icon>
                        </div>
                      </Tooltip>
                    </div>
                    :
                    "Auto-cancel high risk orders"
                }
              </BoldText>
            }
            settingToggleProps={{
              enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.autoCancelOrder,
              action: {
                onAction: handleAutoCancelFraudOrders,
                loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '2',
              },
            }}
            mixpanelKey="Auto_cancel_high_risk_button"
            contentTooltipWithLink={
              dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders)
                ? undefined
                : 'Please grant permission to turn on this feature'
            }
            onClick={handleUpdateScope}
            disabled={!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders)}
            children={
              <RegularText>
                Turn on this feature, and the high-risk orders will be automatically canceled based on the condition you set up.{' '}
                <Link url={'https://docs.ipblocker.io/getting-started/fraud-orders/auto-cancel-high-risk-orders'} target="_blank">
                  Learn more.
                </Link>
              </RegularText>
            }
            content={
              !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.autoCancelOrder ? (
                <InlineGrid columns={!isMobile ? 2 : 1}>
                  <BlockStack gap={'200'}>
                    <div className="cancel-order-checkbox">
                      <Text variant="headingXs" as="h2">
                        Order type
                      </Text>
                      <BlockStack>
                        <Checkbox
                          label="High-risk orders"
                          checked={state.cancelHighRisk}
                          onChange={handleChangeAutoCancelOrder('cancelHighRisk')}
                        />
                        <Checkbox
                          label="Medium-risk orders"
                          checked={state.cancelMediumRisk}
                          onChange={handleChangeAutoCancelOrder('cancelMediumRisk')}
                        />
                      </BlockStack>
                    </div>
                    <div className="cancel-order-checkbox">
                      <Text variant="headingXs" as="h2">
                        Inventory
                      </Text>
                      <BlockStack>
                        <Checkbox
                          label="Re-stock inventory after canceling the orders"
                          checked={state.reStock}
                          onChange={handleChangeAutoCancelOrder('reStock')}
                        />
                      </BlockStack>
                    </div>

                    {/* <div className="cancel-order-checkbox">
                    <Text variant="headingXs" as="h2">Order management</Text>
                    <BlockStack>
                      <Checkbox
                        label="Delete the order immediately (only for COD orders)"
                        checked={state.deleteImmediately}
                        onChange={handleChangeAutoCancelOrder('deleteImmediately')}
                      />
                    </BlockStack>
                  </div> */}
                  </BlockStack>
                  <BlockStack gap={'200'}>
                    <div className="cancel-order-checkbox">
                      <Text variant="headingXs" as="h2">
                        Refund
                      </Text>
                      <BlockStack>
                        <Checkbox
                          label="Auto-refund when canceling the orders"
                          checked={state.refund}
                          onChange={handleChangeAutoCancelOrder('refund')}
                        />
                      </BlockStack>
                    </div>
                    <div className="cancel-order-checkbox">
                      <Text variant="headingXs" as="h2">
                        Notification
                      </Text>
                      <BlockStack>
                        <Checkbox
                          label="Send cancellation confirmation email to customers"
                          checked={state.buyer}
                          onChange={handleChangeAutoCancelOrder('buyer')}
                        />
                        <Checkbox
                          label="Send cancellation notice to store owners"
                          checked={state.merchant}
                          onChange={handleChangeAutoCancelOrder('merchant')}
                        />
                      </BlockStack>
                    </div>
                  </BlockStack>
                </InlineGrid>
              ) : (
                <></>
              )
            }
          />
        </ScrollInToView>

        <div className="mt-16">
          <div>
            <Analytics />
          </div>
        </div>

        <div className="mt-16">
          <div>
            <Table />
          </div>
        </div>
      </FraudOrdersStyled>
    </Layout>
  );
};

export default memo(FraudOrders);
