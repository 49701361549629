import React, { useState, FC, useMemo, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, Collapsible, Link, List, Text } from '@shopify/polaris';
import RegularText from '@/components/RegularText';
import userPlans from '@/hooks/userPlans';

import { useDispatch, useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleIdScrollIntoView } from '@/redux/slice/home.slice';
import { IDScrollIntoView } from '@/constants/enum';

interface FAQItem {
  label: string;
  extend: boolean;
  content: React.ReactNode;
  mixpanelEvent: string;
}

const PricingFAQs: FC = () => {
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const handleClickLink = useCallback(
    (scrollId: IDScrollIntoView) => () => {
      dispatch(handleIdScrollIntoView(scrollId));
    },
    [dispatch],
  );

  const { shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser } = userPlans();

  const initialData: FAQItem[] = useMemo(
    () => [
      {
        label: '1. Can I use the app for free?',
        extend: false,
        content: (
          <RegularText>
            Yes, our app providing a Free plan with a limited access Block, Redirect and Whitelist rules ({maxLimitRules} rules),
            and standard fraud order analytics. To increase the limitation, please consider to our{' '}
            {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
              <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>Shopify Plus plan</Link>
            ) : (
              <>
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>Premium plan</Link> and{' '}
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>Enterprise plan</Link>{' '}
                {!newUser && (
                  <>
                    and <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}> Shopify Plus plan</Link>
                  </>
                )}
              </>
            )}
            .
          </RegularText>
        ),
        mixpanelEvent: 'Faq_1',
      },
      {
        label: '2. What can I do with the free plan?',
        extend: false,
        content: (
          <>
            <RegularText>Our app provides a free plan with a limited rules of these features following:</RegularText>
            <List type="bullet">
              <List.Item>Block: IP address, Location</List.Item>
              <List.Item>Redirect: IP address, Location</List.Item>
              <List.Item>Whitelist: IP address, Location</List.Item>
              <List.Item>Fraud order analytics</List.Item>
              <List.Item>Uses blocking template</List.Item>
            </List>
            <RegularText>
              To increase the limitation, please consider to our{' '}
              {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>Shopify Plus plan</Link>
              ) : (
                <>
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>Premium plan</Link>,{' '}
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>Enterprise plan</Link>, and{' '}
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>
                    Plus plan (for Shopify Plus stores only)
                  </Link>
                </>
              )}
            </RegularText>
          </>
        ),
        mixpanelEvent: 'Faq_2',
      },
      {
        label: '3. How does this app pricing work?',
        extend: false,
        content: (
          <RegularText>
            Our app app pricing is subscription charge based, we will be charged via Shopify Payment, following the{' '}
            <Link
              target="_blank"
              url="https://help.shopify.com/en/manual/your-account/manage-billing/your-invoice/apps#subscriptions"
            >
              app subscriptions charges
            </Link>{' '}
            work flow of Shopify.
          </RegularText>
        ),
        mixpanelEvent: 'Faq_3',
      },
      {
        label: '4. Do I have trials for the paid plan?',
        extend: false,
        content: (
          <RegularText>
            Yes, at the moment, Blockify available 3-day Free trial for{' '}
            {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
              <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>Shopify Plus plan</Link>
            ) : (
              <>
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>Premium</Link>,{' '}
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>Enterprise</Link>{' '}
                {!newUser && (
                  <>
                    and <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}> Shopify Plus plan</Link>
                  </>
                )}
              </>
            )}
            .
          </RegularText>
        ),
        mixpanelEvent: 'Faq_4',
      },
      {
        label: `5. Can I switch back to the free plan when I'm not using the app?`,
        extend: false,
        content: (
          <RegularText>
            Yes, you have the option to downgrade to the free plan during periods when you're not actively using the app. If you
            want to downgrade, please navigate to the Pricing page and then select the Free plan.
          </RegularText>
        ),
        mixpanelEvent: 'Faq_5',
      },
    ],
    [maxLimitRules, shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser, handleClickLink],
  );

  const [listData, setListData] = useState<FAQItem[]>([]);

  useEffect(() => {
    setListData(initialData);
  }, [initialData, shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser]);

  const handleToggle = (index: number) => {
    setListData((prevData) =>
      prevData.map((item, i) => {
        if (i === index) {
          return { ...item, extend: !item.extend };
        }
        return { ...item, extend: false };
      }),
    );
  };

  return (
    <div className="pricing-faqs-container">
      <Text variant="headingLg" as="h4">
        Pricing FAQs
      </Text>

      <Card padding={{ xs: '400', lg: '600' }}>
        <BlockStack gap="500">
          {listData?.map((question, index) => (
            <React.Fragment key={question.label}>
              <Button size="large" fullWidth textAlign="left" onClick={() => handleToggle(index)}>
                {question.label}
              </Button>

              <Collapsible
                open={question.extend}
                id="basic-collapsible"
                transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
              >
                <Box padding="200">
                  <RegularText>{question.content}</RegularText>
                </Box>
              </Collapsible>
            </React.Fragment>
          ))}
        </BlockStack>
      </Card>
    </div>
  );
};

export default PricingFAQs;
