/* eslint-disable jsx-a11y/anchor-is-valid */
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { useGa4 } from '@/hooks/useGa4';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { IGA4 } from '@/types/components';
import { Badge, Button, Card, Collapsible, Icon, InlineGrid } from '@shopify/polaris';
import { AlertTriangleIcon, XIcon } from '@shopify/polaris-icons';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leftRecommendApp } from './configRecommend';
import { RecommendedAppsStyled } from './styled';

const RecommendedApps = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);
  const banner = useSelector(bannerSelector);
  // const [popoverActive, setPopoverActive] = useState(true);

  // const togglePopoverActive = useCallback(
  //   () => setPopoverActive((popoverActive) => !popoverActive),
  //   []
  // );

  const handleVisibleRecommendedApp = () => {
    // setPopoverActive(false)
    dispatch(handleVisibleBanner({
      key: 'recommendedApp',
      value: false,
    }))
  };
  const { handleGa4 } = useGa4();
  const handleClick = useCallback(
    (item: IGA4) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
      if (!gaFour[item.key as keyof typeof gaFour]) {
        handleGa4(item.clickGA);
      }
    },
    [gaFour, handleGa4],
  );
  // const activator = <Button onClick={togglePopoverActive} icon={MenuHorizontalIcon} variant='plain' />;

  return (
    <Collapsible id="" open={banner.recommendedApp} expandOnPrint transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}>
      <RecommendedAppsStyled>
        <div className='btn-cancel'>
          <Button icon={XIcon} variant='plain' onClick={handleVisibleRecommendedApp}></Button>
        </div>
        <div className='btn-dismiss'>
          <Badge tone="attention">Suggested by experts</Badge>
          {/* <Popover
            active={popoverActive}
            activator={activator}
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
          >
            <ActionList
              actionRole="menuitem"
              items={[{
                content: 'Dismiss',
                onAction: handleVisibleRecommendedApp
              }]}
            />
          </Popover> */}
        </div>
        <div className='d-flex mt-8'>
          <Icon source={AlertTriangleIcon} tone="critical" />
          <BoldText>You are vulnerable to revenue loss and business disruptions without a solid store protection strategy</BoldText>
        </div>
        <div className='mt-8'>
          <RegularText>With right strategies and tools, you can manage risks, safeguard your earnings, and get ahead of fraud. Take your mind out of fraud, disruption and focus on growing business.</RegularText>
        </div>
        <div className="mt-16" id="banner-2">
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={'400'}>
            {leftRecommendApp.map((item) => {
              return (
                <Card padding={'400'} key={item.title}>
                  <div className="recommend-app-container">
                    <div className="recommend-app-content">
                      <div className='d-flex align-center'>
                        <div>{item.icon}</div>
                        <BoldText>{item.title}</BoldText>
                      </div>
                      <RegularText>{item.description}</RegularText>
                    </div>
                    <div>
                      <Button onClick={handleClick(item)}>{item.buttonLabel}</Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>
      </RecommendedAppsStyled>
    </Collapsible>
  );
};

export default memo(RecommendedApps);
