import { BlockStack, Collapsible, InlineStack, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';
// import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { ColorPickerCustomerStyled } from './styled';

interface ColorPickerCustomProps {
  label: string;
  color: string;
  onChange: (value: string) => void;
  requiredIndicator?: boolean;
}

const ColorPickerV2: React.FC<ColorPickerCustomProps> = ({ label, color, onChange, requiredIndicator }) => {
  // const dispatch = useDispatch();

  const [isShowColorPicker, setIsShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useColor(color || '');

  // const handleOnBlur = () => {
  //   const colorDescriptor = colorString.get(color);

  //   // Nếu color hợp lệ, lấy mảng RGB hoặc RGBA và chuyển sang hex, nếu không thì dùng defaultColor
  //   const hexColor = colorDescriptor ? colorString.to.hex(colorDescriptor.value) : defaultColor;

  //   dispatch(
  //     handleChangePreview({
  //       [keyColor]: hexColor,
  //     }),
  //   );
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeColor = useCallback(
    debounce((color: string) => {
      onChange?.(color);
    }, 300),
    [onChange],
  );

  return (
    <ColorPickerCustomerStyled bgColorPreview={color}>
      <BlockStack gap="200">
        <BlockStack gap="100">
          <Text as="span" variant="bodyMd">
            {label} {requiredIndicator ? <span style={{ color: 'red' }}>*</span> : ''}
          </Text>

          <InlineStack gap="300">
            <div className="preview-color" onClick={() => setIsShowColorPicker(!isShowColorPicker)}></div>

            <div style={{ flex: 1 }}>
              <TextField
                label
                onChange={(value) => onChange(value)}
                autoComplete=""
                value={color}
                // onBlur={handleOnBlur}
              />
            </div>
          </InlineStack>
        </BlockStack>

        <Collapsible
          id="collapsible-color-picker"
          open={isShowColorPicker}
          expandOnPrint
          transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
        >
          <ColorPicker
            // hideAlpha
            hideInput
            height={100}
            onChange={(color) => {
              setSelectedColor(color);
              debounceChangeColor(color.hex);
            }}
            color={selectedColor}
          />
        </Collapsible>
      </BlockStack>
    </ColorPickerCustomerStyled>
  );
};

export default ColorPickerV2;
