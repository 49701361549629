import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const AnalyticsStyled = styled.div`
  .btn-copy {
    opacity: 0;
  }
  .btn-disable {
    width: 1.25rem;
    display: block;
    .Polaris-Icon {
      margin: 0;
    }
  }
  .address-container {
    position: relative;
  }
  /* .Polaris-IndexTable {
    padding: 0 0.5rem;
  } */
  .Polaris-IndexTable__TableRow:hover {
    .btn-copy {
      opacity: 1;
    }
  }
  .text-decoration {
    .Polaris-Text--bodySm {
      text-decoration: underline;
    }
    .Polaris-Badge--toneWarning {
      background-color: #ffea8a;
    }
  }
  .Polaris-IndexFilters__ActionWrap {
    padding-right: 3rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      padding-right: 3.25rem;
    }
  }
  .table-container {
    position: relative;
  }
  .export-btn {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
  }
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    .Polaris-ResourceList {
      min-width: 1050px;
    }
    .Polaris-ResourceList__FiltersWrapper {
      min-width: 1050px;
    }
  }
  .Polaris-LegacyCard {
    margin-top: 0px;
  }
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
  }
  .card-chart-container {
    .chart-container {
      .badge-demo {
        margin-left: 8px;
      }
    }
  }

  .va-risk-score {
    .Polaris-Icon {
      margin: 0;
      margin-left: 0.25rem;
    }
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.25rem;
    }
  }
  .pointer {
    cursor: pointer;
  }
  #risk-score {
    .w-57 {
      width: 57px;
      display: flex;
      justify-content: end;
    }
  }
  #risk-score-header {
    width: 100px;
  }
  #vpn-header {
    width: 100px;
  }
  #vpn-status :first-child {
    margin-left: 20px;
  }
  .text-link {
    color: #005bd3;
    cursor: pointer;
  }
  .banner-warning-demo-data {
    .Polaris-Link--monochrome {
      color: #005bd3;
    }
  }
  /* .Polaris-Banner {
    .Polaris-Link--monochrome {
      font-weight: 700;
    }
    .Polaris-ButtonGroup {
      .Polaris-Button {
        color: #ffffff;
        background: #1a1a1a;
        box-shadow: 0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
          -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.0625rem 0rem 0.0625rem #000 inset,
          0rem 0.0625rem 0rem 0rem #000 inset;
        .Polaris-Button__Content {
          font-weight: 650;
        }
      }
    }
  } */
  .text-blur {
    .Polaris-Text--regular {
      filter: blur(6px);
    }
  }
  .w-300 {
    .Polaris-Text--root {
      max-width: 300px;
      white-space: pre-line;
    }
  }

  div._Container_3h6ct_1 {
    justify-content: center !important;
  }
  .hover-card {
    position: relative;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 11px;
  }

  .hover-card:hover .overlay {
    opacity: 1;
  }
`;
