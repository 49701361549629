import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Ga4Event } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { useGa4 } from '@/hooks/useGa4';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import { gaFourSelector } from '@/redux/slice/session.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Button, Card, Link, Modal, Tooltip } from '@shopify/polaris';
import { DuplicateIcon, HideIcon, ViewIcon } from '@shopify/polaris-icons';

import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralSettingsStyled } from './styled';
import SettingSwitch from '@/components/SettingSwitch';

function GeneralSettings() {
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const adminURL = apiCaller.useGetAdminURLQuery(config.shop, { skip: isSkip });
  const [enableApp, enableStatus] = apiCaller.useEnableAppMutation();

  const [state, setState] = useState({
    modalOpen: false,
    copied: false,
    showFullUrl: false,
  });

  const handleCopyUrl = useCallback(() => {
    navigator.clipboard.writeText(adminURL?.data?.url || '').then(() => {
      setState({ ...state, copied: true });
      dispatch(
        slice.toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Copied',
          error: false,
        }),
      );
    });
  }, [adminURL?.data?.url, dispatch, state]);

  const handleShowUrl = useCallback(() => {
    setState({ ...state, showFullUrl: true });
  }, [state]);

  const handleHideUrl = useCallback(() => {
    setState({ ...state, showFullUrl: false });
  }, [state]);

  const handleOpenModalEnableApp = useCallback(() => {
    if (dataSettings?.settings.user.enableApp) {
      setState({ ...state, modalOpen: true });
    } else {
      enableApp(!dataSettings?.settings.user.enableApp).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }
  }, [dataSettings?.settings.user.enableApp, dispatch, enableApp, state]);

  const handleConfirmToggle = useCallback(() => {
    enableApp(!dataSettings?.settings.user.enableApp).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setState({ ...state, modalOpen: false });
  }, [dataSettings?.settings.user.enableApp, dispatch, enableApp, state]);

  const handleCancelToggle = useCallback(() => {
    setState({ ...state, modalOpen: false });
  }, [state]);

  const handleOpenAdminAccess = useCallback(() => {
    window.open(adminURL.data?.url);
  }, [adminURL.data?.url]);

  if (!gaFour.generalSetting) {
    handleGa4(Ga4Event.GeneralSetting);
  }

  return (
    <GeneralSettingsStyled>
      <SettingSwitch
        trackAction="setting_activate_app_blockify"
        title="Activate Blockify"
        settingToggleProps={{
          enabled: dataSettings?.settings.user.enableApp,
          action: {
            loading: enableStatus.isLoading || isLoading,
            onAction: handleOpenModalEnableApp,
          },
        }}
      />

      {/* <div className="mt-16">
        <Card>
          <BoldText>Restrict bypass attempts</BoldText>
          <div className="mt-16">
            <InformationBanner
              isVisible={customizeBanner}
              title="Highly recommend"
              onDismiss={() => {
                dispatch(slice.banner.actions.handleCustomizeCode(false));
              }}
            >
              Adding our code into your theme store will <b>restrict potential bypass attempts.</b> Rest assured that turning off
              or uninstalling the app will make our code ineffective.
            </InformationBanner>
          </div>

          <p>Instruction:</p>
          <div className="mt-8">
            <RegularText>1. From your online store, click Themes</RegularText>
            <br />
            <RegularText>
              2. Click{' '}
              <Button onClick={handleGetThemeEditorUrl} variant="plain">
                Edit code
              </Button>{' '}
              in your current theme drop-down menu
            </RegularText>
            <br />

            <RegularText>
              3. In the &nbsp;<code>{`</>theme.liquid`}</code>&nbsp;file, locate the
              <span>
                &nbsp;<code>{`</head>`}</code>&nbsp;
              </span>
              tag and paste the following code just before it.
            </RegularText>
            <br />
            <RegularText>4. Click "Save"</RegularText>
            <br />
            <RegularText>
              <b>Note:</b> If the changes don't reflect on your store after saving, please contact our support team for
              assistance. We're here to help you add the code.
            </RegularText>
          </div>
          <div className="customize-code">
            <div className="copy-code">
              <Tooltip content="Copy">
                <Button
                  icon={DuplicateIcon}
                  onClick={() => {
                    dispatch(
                      slice.toastSlice.actions.handleToast({
                        content: 'Copied',
                        isOpen: true,
                        error: false,
                      }),
                    );
                    navigator.clipboard.writeText(customizeCode?.data?.code || '');
                  }}
                />
              </Tooltip>
            </div>
            {customizeCode.isLoading ? (
              <div className="mt-16">
                <SkeletonBodyText lines={6} />
              </div>
            ) : (
              <pre>{customizeCode?.data?.code || ''}</pre>
            )}
          </div>
        </Card>
      </div> */}
      <div className="mt-16">
        <Card>
          <BoldText>Admin's access URL</BoldText>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0px' }}>
            <div style={{ maxWidth: '80%', wordBreak: 'break-word' }}>
              <Link external removeUnderline onClick={handleOpenAdminAccess}>
                {state.showFullUrl ? adminURL.data?.url : `${adminURL.data?.url?.substring(0, 20)}***************` || ''}
              </Link>
            </div>
            <div className="d-flex">
              <Tooltip content={!state.showFullUrl ? 'Show' : 'Hide'}>
                <Button
                  icon={state.showFullUrl ? ViewIcon : HideIcon}
                  onClick={state.showFullUrl ? handleHideUrl : handleShowUrl}
                />
              </Tooltip>
              <div className="ml-8">
                <Tooltip content="Copy">
                  <Button icon={DuplicateIcon} onClick={handleCopyUrl} />
                </Tooltip>
              </div>
            </div>
          </div>
          <RegularText>
            Access via the above URL will not be blocked under any circumstances. Please don't disclose the link.
          </RegularText>
        </Card>
      </div>

      <Modal
        open={state.modalOpen}
        onClose={handleCancelToggle}
        title="Turn off Blockify"
        primaryAction={{
          content: 'Turn off',
          onAction: handleConfirmToggle,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCancelToggle,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>
            If you turned off, Blockify cannot protect your website from unauthorized and fraudulent visitors.
          </RegularText>
        </Modal.Section>
      </Modal>
    </GeneralSettingsStyled>
  );
}

export default memo(GeneralSettings);
