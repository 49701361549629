import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  IconProps,
  InlineGrid,
  InlineStack,
  Text,
  TextProps,
} from '@shopify/polaris';
import { AlertTriangleIcon, CheckIcon, ShieldCheckMarkIcon, XIcon } from '@shopify/polaris-icons';
import { BadgeProps } from '@shopify/polaris/build/ts/src/components/Badge';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PromotionsCardStyled } from './styled';

interface promotionsItemDataProps {
  title: {
    text: string;
    tone: TextProps['tone'];
  };
  iconTitle: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  content: string;
  subContent: {
    items: string[];
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  badge?: {
    text: string;
    tone: BadgeProps['tone'];
  };
  additionalText: string;
  button?: {
    text: string;
    link: string;
  };
}

const promotionsData: promotionsItemDataProps[] = [
  {
    title: {
      text: 'You might not know this!',
      tone: 'success',
    },
    iconTitle: {
      icon: AlertTriangleIcon,
      tone: 'warning',
    },
    content: 'Did you know that PayPal and Stripe can hold your funds and limit your account?',
    subContent: {
      items: ['They can hold your funds for up to 21 days', 'Limit your accounts', 'Ban your account permanently'],
      icon: AlertTriangleIcon,
      tone: 'warning',
    },
    additionalText: 'PayPal or Stripe may randomly check if your orders are being delivered.',
  },
  {
    title: {
      text: 'Strong Account',
      tone: 'success',
    },
    iconTitle: {
      icon: ShieldCheckMarkIcon,
      tone: 'success',
    },
    badge: {
      text: 'Verified by PayPal',
      tone: 'success',
    },
    content: 'Strong PayPal or Stripe account looks like this:',
    subContent: {
      items: ['No random holds or bans', 'Fewer chargebacks and holds', 'A better relationship between PayPal and Stripe'],
      icon: CheckIcon,
      tone: 'success',
    },
    additionalText: 'You can automate this by sending proof of delivery to PayPal using Synctrack.',
    button: {
      text: 'Yes, help me strengthen my account',
      link: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    },
  },
];

const PromotionsCard = () => {
  const banner = useSelector(bannerSelector);
  const dispatch = useDispatch();

  const handleDismissBanner = () => {
    dispatch(
      handleVisibleBanner({
        key: 'bannerPromotionSynctrack',
        value: false,
      }),
    );
  };

  return (
    <Collapsible
      id=""
      open={banner.bannerPromotionSynctrack}
      expandOnPrint
      transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
    >
      <PromotionsCardStyled>
        <div className="dismiss-promotion">
          <Button icon={XIcon} variant="tertiary" onClick={handleDismissBanner} />
        </div>
        <Card>
          <div className="mb-16">
            <Text as="h3" variant="headingMd">
              Suggested by experts
            </Text>
          </div>

          <InlineGrid gap="400" columns={{ xs: 1, md: 2 }}>
            {promotionsData.map((item, idx) => (
              <Card key={item.title.text}>
                <BlockStack gap="300">
                  <InlineStack align="space-between" blockAlign="center" gap="050">
                    <InlineStack gap="050">
                      <Icon source={item.iconTitle.icon} tone={item.iconTitle.tone} />

                      <InlineStack align="center" gap="050" blockAlign="center">
                        <Text id={idx === 0 ? 'warning' : ''} as="h4" variant="headingMd" tone={item.title.tone}>
                          {item.title.text}
                        </Text>
                      </InlineStack>
                    </InlineStack>
                    {item?.badge && <Badge tone={item.badge.tone}>{item.badge.text}</Badge>}
                  </InlineStack>

                  <Box paddingInlineStart="500">
                    <BlockStack gap="200">
                      <Text as="h6" variant="headingMd">
                        {item.content}
                      </Text>
                      {item.subContent.items.map((sub) => (
                        <div key={sub} style={{ display: 'flex', gap: '5px' }}>
                          <Icon source={item.subContent.icon} tone={item.subContent.tone} />
                          <div style={{ flex: 1 }}>
                            <Text as="span">{sub}</Text>
                          </div>
                        </div>
                      ))}

                      <Text as="span">{item.additionalText}</Text>
                    </BlockStack>
                  </Box>
                  {item?.button && (
                    <Button onClick={() => window.open(item.button?.link, '_blank')} size="large">
                      {item.button?.text}
                    </Button>
                  )}
                </BlockStack>
              </Card>
            ))}
          </InlineGrid>
        </Card>
      </PromotionsCardStyled>
    </Collapsible>
  );
};

export default PromotionsCard;
