import BoldText from '@/components/BoldText';
import ColorPickerV2 from '@/components/ColorPickerV2';
import { colors, Enum } from '@/constants';
import userPlans from '@/hooks/userPlans';
import settingsSlice, { templateSelector } from '@/redux/slice/settings.slice';
import { ISettings } from '@/types/components';
import { BlockStack, Card, Divider, Select, TextField, Tooltip } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomImage from '../../fullScreen/CustomImage';
interface Props {
  setFocusId: (id: string) => void;
  setFiles: (value: { image: null | File; backgroundImage: null | File }) => void;
  files: { image: null | File; backgroundImage: null | File };
}

const Editor = ({ setFocusId, setFiles, files }: Props) => {
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();

  const template = useSelector(templateSelector);

  const [isSaved, setIsSaved] = useState({
    logo: false,
    background: false,
  });

  const handleOnChangeTitle = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        title: {
          active: true,
          text: value,
          color: template.title.color,
        },
      }),
    );
  };

  const handleOnChangeDescription = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        description: {
          active: true,
          text: value,
          color: template.description.color,
        },
      }),
    );
  };

  const handleOnChangeSuperTitle = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        superTitle: {
          active: true,
          text: value,
          color: template.superTitle.color,
        },
      }),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    (value: ISettings.CustomTemplate) => {
      dispatch(settingsSlice.actions.handleTemplate(value));
    },
    [dispatch],
  );

  return userPlanFree ? (
    <BlockStack gap="300">
      {(template.superTitle.active || template?.title?.active) && (
        <CardCustom label="Title">
          <BlockStack gap="300">
            {template.superTitle.active && (
              <>
                <TextField
                  id="custom-template-superTitle"
                  maxLength={64}
                  label="Super text"
                  value={template.superTitle?.text}
                  autoComplete="off"
                  showCharacterCount
                />
                <ColorPickerV2 color={template.superTitle.color} label="Color" onChange={(color) => {}} />
              </>
            )}

            {template?.title?.active && (
              <>
                <TextField
                  id="custom-template-title"
                  maxLength={64}
                  label="Text"
                  value={template.title?.text}
                  autoComplete="off"
                  showCharacterCount
                />
                <ColorPickerV2 label="Color" color={template.title.color} onChange={(color) => {}} />
              </>
            )}
          </BlockStack>
        </CardCustom>
      )}

      {template.description.active && (
        <CardCustom label="Description">
          <BlockStack gap="300">
            <TextField
              maxLength={256}
              label="Text"
              id="custom-template-content"
              value={template.description?.text}
              autoComplete="off"
              showCharacterCount
            />
            <ColorPickerV2 label="Color" color={template.description.color} onChange={(color) => {}} />
          </BlockStack>
        </CardCustom>
      )}

      {template.background.active && (
        <CardCustom label="Background">
          <BlockStack gap="300">
            <Select
              label="Select type"
              options={
                Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT)
                  ? [{ label: 'Background Gradient', value: Enum.BackgroundType.GRADIENT }]
                  : [
                      { label: 'Background Color', value: Enum.BackgroundType?.Color },
                      { label: 'Background Image', value: Enum.BackgroundType.Image },
                    ]
              }
              onChange={(value) => {}}
              value={template.background?.type.toString()}
            />
            {Number(template.background?.type) === Number(Enum.BackgroundType.Image) ? (
              <CustomImage
                id="custom-template-background"
                isSaved={isSaved.background}
                handleSaved={() => {}}
                handleUpload={(file: File, src: string) => {}}
              />
            ) : null}
            {Number(template.background?.type) === Number(Enum.BackgroundType?.Color) ? (
              <ColorPickerV2
                label="Color"
                color={template.background.value}
                onChange={(color) => {
                  handleChange({
                    ...template,
                    background: {
                      active: true,
                      colorFrom: null,
                      colorTo: null,
                      type: Number(Enum.BackgroundType?.Color),
                      value: color,
                    },
                  });
                }}
              />
            ) : null}
            {Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT) ? (
              <BlockStack gap="300">
                <ColorPickerV2 label="From" color={template.background.colorFrom || ''} onChange={(color) => {}} />
                <ColorPickerV2 label="To" color={template.background.colorTo || ''} onChange={(color) => {}} />
              </BlockStack>
            ) : null}
          </BlockStack>
        </CardCustom>
      )}

      {template?.logoImage.active && (
        <CardCustom label="Logo (optional)">
          <CustomImage
            id="custom-template-logo"
            isSaved={isSaved.logo}
            handleSaved={() => {}}
            handleUpload={(file: File, src: string) => {}}
          />
        </CardCustom>
      )}
    </BlockStack>
  ) : (
    <BlockStack gap="300">
      {(template.superTitle.active || template?.title?.active) && (
        <CardCustom label="Title">
          <BlockStack gap="300">
            {template.superTitle.active && (
              <>
                <TextField
                  id="custom-template-superTitle"
                  maxLength={64}
                  label="Super text"
                  value={template.superTitle?.text}
                  onChange={handleOnChangeSuperTitle}
                  autoComplete="off"
                  showCharacterCount
                  onBlur={() => {
                    setFocusId('');
                  }}
                  onFocus={() => {
                    setFocusId('custom-template-superTitle');
                  }}
                />
                <ColorPickerV2
                  color={template.superTitle.color}
                  label="Color"
                  onChange={(color) => {
                    handleChange({
                      ...template,
                      superTitle: {
                        active: true,
                        text: template.superTitle?.text,
                        color: color,
                      },
                    });
                  }}
                />
              </>
            )}

            {template?.title?.active && (
              <>
                <TextField
                  id="custom-template-title"
                  maxLength={64}
                  label="Text"
                  value={template.title?.text}
                  onChange={handleOnChangeTitle}
                  autoComplete="off"
                  showCharacterCount
                  onBlur={() => {
                    setFocusId('');
                  }}
                  onFocus={() => {
                    setFocusId('custom-template-title');
                  }}
                />

                <ColorPickerV2
                  label="Color"
                  color={template.title.color}
                  onChange={(color) => {
                    handleChange({
                      ...template,
                      title: {
                        active: true,
                        text: template.title?.text,
                        color: color,
                      },
                    });
                  }}
                />
              </>
            )}
          </BlockStack>
        </CardCustom>
      )}

      {template.description.active && (
        <CardCustom label="Description">
          <BlockStack gap="300">
            <TextField
              maxLength={256}
              label="Text"
              id="custom-template-content"
              value={template.description?.text}
              onChange={handleOnChangeDescription}
              autoComplete="off"
              showCharacterCount
              onFocus={() => {
                setFocusId('custom-template-content');
              }}
              onBlur={() => {
                setFocusId('');
              }}
            />
            <ColorPickerV2
              label="Color"
              color={template.description.color}
              onChange={(color) => {
                handleChange({
                  ...template,
                  description: {
                    active: true,
                    text: template.description?.text,
                    color: color,
                  },
                });
              }}
            />
          </BlockStack>
        </CardCustom>
      )}

      {template.background.active && (
        <CardCustom label="Background">
          <BlockStack gap="300">
            <Select
              label="Select type"
              options={
                Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT)
                  ? [{ label: 'Background Gradient', value: Enum.BackgroundType.GRADIENT }]
                  : [
                      { label: 'Background Color', value: Enum.BackgroundType?.Color },
                      { label: 'Background Image', value: Enum.BackgroundType.Image },
                    ]
              }
              onChange={(value) => {
                dispatch(
                  settingsSlice.actions.handleTemplate({
                    ...template,
                    background: {
                      active: true,
                      colorFrom: null,
                      colorTo: null,
                      type: Number(value[0]),
                      value: template.background?.value,
                    },
                  }),
                );
              }}
              value={template.background?.type.toString()}
            />
            {Number(template.background?.type) === Number(Enum.BackgroundType.Image) ? (
              <div className="mt-8">
                <CustomImage
                  id="custom-template-background"
                  isSaved={isSaved.background}
                  handleSaved={() => {
                    setIsSaved({
                      ...isSaved,
                      background: false,
                    });
                  }}
                  handleUpload={(file: File, src: string) => {
                    setFiles({
                      ...files,
                      backgroundImage: file,
                    });
                    dispatch(
                      settingsSlice.actions.handleTemplate({
                        ...template,
                        background: {
                          active: true,
                          colorFrom: null,
                          colorTo: null,
                          type: Number(Enum.BackgroundType.Image),
                          value: src,
                        },
                      }),
                    );
                  }}
                />
              </div>
            ) : null}

            {Number(template.background?.type) === Number(Enum.BackgroundType?.Color) ? (
              <ColorPickerV2
                label="Color"
                color={template.background.value}
                onChange={(color) => {
                  handleChange({
                    ...template,
                    background: {
                      active: true,
                      colorFrom: null,
                      colorTo: null,
                      type: Number(Enum.BackgroundType?.Color),
                      value: color,
                    },
                  });
                }}
              />
            ) : null}

            {Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT) ? (
              <BlockStack gap="300">
                <ColorPickerV2
                  label="From"
                  color={template.background?.colorFrom || ''}
                  onChange={(color) => {
                    handleChange({
                      ...template,
                      background: {
                        active: true,
                        colorFrom: color,
                        colorTo: template.background?.colorTo,
                        type: template.background?.type,
                        value: '',
                      },
                    });
                  }}
                />

                <ColorPickerV2
                  label="To"
                  color={template.background?.colorTo || ''}
                  onChange={(color) => {
                    handleChange({
                      ...template,
                      background: {
                        active: true,
                        colorFrom: template.background?.colorFrom,
                        colorTo: color,
                        type: template.background?.type,
                        value: '',
                      },
                    });
                  }}
                />
              </BlockStack>
            ) : null}
          </BlockStack>
        </CardCustom>
      )}

      {template?.logoImage.active && (
        <CardCustom label="Logo (optional)">
          <CustomImage
            id="custom-template-logo"
            isSaved={isSaved.logo}
            handleSaved={() => {
              setIsSaved({
                ...isSaved,
                logo: false,
              });
            }}
            handleUpload={(file: File, src: string) => {
              setFiles({
                ...files,
                image: file,
              });
              dispatch(
                settingsSlice.actions.handleTemplate({
                  ...template,
                  logoImage: {
                    active: true,
                    value: src,
                  },
                }),
              );
            }}
          />
        </CardCustom>
      )}
    </BlockStack>
  );
};

export default memo(Editor);

type CardCustomProps = {
  label: string;
  children: React.ReactElement;
};

const CardCustom = ({ label, children }: CardCustomProps) => {
  const { userPlanFree, shopifyPlanPlus } = userPlans();
  const contentTooltip =
    userPlanFree && shopifyPlanPlus ? 'Available on Shopify Plus plan' : 'Available on Premium plan or above';

  return userPlanFree ? (
    <Tooltip content={contentTooltip} preferredPosition="above">
      <div
        style={{
          position: 'relative',
          pointerEvents: userPlanFree ? 'none' : 'auto',
          opacity: userPlanFree ? 0.8 : 1,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.bgHover,
            zIndex: 10,
          }}
        />
        <Card>
          <BoldText>{label}</BoldText>
          <div className="mt-8 mb-12">
            <Divider />
          </div>
          {children}
        </Card>
      </div>
    </Tooltip>
  ) : (
    <Card>
      <BoldText>{label}</BoldText>
      <div className="mt-8 mb-12">
        <Divider />
      </div>
      {children}
    </Card>
  );
};
