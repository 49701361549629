import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const FraudOrdersStyled = styled.div`
  .analytics-container {
    padding: 1rem;
    background-color: ${colors.gray_background};
    .Polaris-Link {
      font-weight: 700;
    }
    .analytics-items-title {
      display: flex;
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.5rem;
      }
      &:hover {
        cursor: pointer;
        color: ${colors.bgHoverTitle};
      }
    }
    .analytics-items-title-default {
      display: flex;
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.5rem;
      }
    }
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.25rem;
    }
  }
  #fraudOrders {
    background-color: white;
    .Polaris-Text--root {
      font-size: 14px;
      font-weight: 650;
    }

    padding-left: 0;
    cursor: auto;
    .Polaris-Text--medium {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 650px;
    }
  }
  #demoDataOrder {
    border: 1px solid rgb(229 229 229);
  }
  .Polaris-IndexFilters__ActionWrap {
    padding-right: 3rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      padding-right: 3.25rem;
    }
  }
  .export-btn {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
  }
  .Polaris-Link--monochrome {
    color: rgba(0, 91, 211, 1);
  }
  .pointer {
    cursor: pointer;
  }
  .fraud-score {
    .Polaris-Icon {
      margin: 0;
      margin-left: 0.25rem;
    }
  }
  .div-disable {
    pointer-events: none;
    opacity: 0.5;
  }
  .range {
    max-width: 50%;
    position: relative;
    .risk-score {
      position: absolute;
      bottom: 0;
      right: -90px;
    }
  }
  .checkout-rules-banner-heading {
    display: flex;
    justify-content: space-between;
  }
`;
