import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector, handleBlockingTemplateId } from '@/redux/slice/dataSettings.slice';
import settingsSlice, { cssSelector } from '@/redux/slice/settings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { ISettings } from '@/types/components';
import { BlockStack, Card, ChoiceList, Divider, SkeletonBodyText, Text } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Template = () => {
  const dispatch = useDispatch();

  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const customCss = useSelector(cssSelector);

  const templates = apiCaller.useGetTemplatesQuery(config?.shop, { skip: isSkip });
  const [fetchTemplateDetail] = apiCaller.useLazyFetchBlockingTemplateQuery();

  const handleCardClick = async (id: string) => {
    try {
      const { data: dataTemplate } = await fetchTemplateDetail({ templateId: +id });

      if (dataTemplate) {
        dispatch(handleBlockingTemplateId(+id));

        const template: ISettings.CustomTemplate = {
          title: {
            active: dataTemplate.properties.title?.active || false,
            text: dataTemplate.properties.title?.text || '',
            color: dataTemplate.properties.title?.color || '',
          },
          description: {
            active: dataTemplate.properties.description?.active || false,
            text: dataTemplate.properties.description?.text || '',
            color: dataTemplate.properties.description?.color || '',
          },
          background: {
            active: dataTemplate.properties.background?.active || false,
            colorFrom: dataTemplate.properties.background?.colorFrom || null,
            colorTo: dataTemplate.properties.background?.colorTo || null,
            type: dataTemplate.properties.background?.type || 0,
            value: dataTemplate.properties.background?.value || '',
          },
          superTitle: {
            active: dataTemplate.properties.superTitle?.active || false,
            text: dataTemplate.properties.superTitle?.text || '',
            color: dataTemplate.properties.superTitle?.color || '',
          },
          logoImage: {
            active: dataTemplate.properties?.logoImage?.active || false,
            value: dataTemplate.properties?.logoImage?.value || '',
          },
        };
        dispatch(settingsSlice.actions.handleTemplate(template));
        dispatch(settingsSlice.actions.handleTemplateId(+id));
        dispatch(
          settingsSlice.actions.handleInput({
            titleColor: template.title.color,
            backgroundColor: template.background.value,
            contentColor: template.description.color,
            superTitleColor: template.superTitle.color,
            backgroundGradientFrom: template.background.colorFrom || '',
            backgroundGradientTo: template.background.colorTo || '',
          }),
        );
        dispatch(settingsSlice.actions.handleCss(dataTemplate.customCss));
        dispatch(settingsSlice.actions.handleCssBackup(customCss));
      }
    } catch {
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Save failure',
          error: false,
        }),
      );
    }
  };

  const optionsTemplate = templates.data?.listTemplates?.map((item) => ({
    label: item?.templateName + `${item.isDefault ? ' (default)' : ''}`,
    value: item?.id.toString(),
  }));

  const idTemplateDefault = templates.data?.listTemplates?.find((item) => item.isDefault)?.id?.toString()!;

  return (
    <>
      <Card>
        <BlockStack gap="200">
          <Text as="h6" variant="headingMd">
            Blocking template
          </Text>
          <Divider />

          {templates.isLoading ? (
            <>
              <SkeletonBodyText />
              <SkeletonBodyText />
              <SkeletonBodyText />
            </>
          ) : (
            <ChoiceList
              title="Select a type"
              choices={optionsTemplate || []}
              selected={[dataSettings?.settings.blockingTemplateId?.toString()! || idTemplateDefault]}
              onChange={(selected) => handleCardClick(selected[0])}
            />
          )}
        </BlockStack>
      </Card>
    </>
  );
};

export default memo(Template);
