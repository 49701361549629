import DropZoneCustom from '@/components/DropZoneCustom';
import { CriteriaType, IpRangeType, IPTypes } from '@/constants/enum';
import { generateCIDR, uniq, validateIPRange } from '@/helpers';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import blockListSlice, { errorCSVSelector, fileDefaultSelector, inputIPMethodSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { BlockStack, Box, Collapsible, FormLayout, InlineError, InlineStack, Link, Select, Text, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  label: string;
  error?: string;
  placeholder?: string;
}

const IpAddress = ({ label, placeholder }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { data }] = apiCaller.useUpsertRuleMutation();
  const getTemplateCSVIp = apiCaller.useGetTemplateCSVIpQuery();
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const { userPlanFree, userPlanPremium } = UserPlans();
  const blockRuleSelected = useSelector(settingSelector);
  const errorCSV = useSelector(errorCSVSelector);
  const fileDefault = useSelector(fileDefaultSelector);
  const inputIPMethod = useSelector(inputIPMethodSelector);
  const { criteria, ipRangeType, id } = blockRuleSelected;
  const handleChange = (ipAddress: string) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
    if (!!errorCSV) {
      dispatch(blockListSlice.actions.handleErrorCSV(''))
    }
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress,
      }),
    );
  };

  const handleSelectTypeIpRange = (value: string) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipRangeType: value as IpRangeType,
        ipAddress: '',
        ipEnd: '',
        ipStart: '',
      }),
    );
  };

  const handleChangeIpRangeValue = (key: keyof typeof blockRuleSelected) => (value: string) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }

    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  const handleOnBlurIpRangeValue = () => {
    const error: string = validateIPRange(blockRuleSelected?.ipStart || '', blockRuleSelected?.ipEnd || '');

    if (error) {
      useError.handleErrorRule(error);
    } else {
      dispatch(
        blockListSlice.actions.handleSetting({
          ...blockRuleSelected,
          ipAddress: generateCIDR(blockRuleSelected?.ipStart || '', blockRuleSelected?.ipEnd || ''),
        }),
      );
    }
  };

  const onRemove = (value: string) => {
    if (blockRuleSelected.ipAddress) {
      useError.removeErrorRule();
    } else {
      useError.handleErrorRule('IP address is required');
    }
    const listIp = blockRuleSelected.ipAddress ? [...blockRuleSelected.ipAddress?.split(',')] : [];
    const newListip = listIp.filter((ip) => {
      return ip.trim() !== value;
    });
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress: newListip.join(','),
      }),
    );
  };

  const handleFileUpload = (acceptedFiles: File[]) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
    if (acceptedFiles.length > 0) {
      dispatch(blockListSlice.actions.handleChangeFileDefault(acceptedFiles[0]));
    } else {
      dispatch(blockListSlice.actions.handleChangeFileDefault((null)));
    }
  };

  const handleInputMethodChange = (value: IPTypes) => {
    dispatch(blockListSlice.actions.handleChangeInputIPMethod((value)));
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
  };
  return (
    <div className="block-ip-address">
      <BlockStack gap="300">
        <Collapsible
          id=""
          open={blockRuleSelected.criteria === CriteriaType.IpRanges}
          transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
        >
          <Box width="200px">
            <Select
              label="Select IP range type"
              options={[
                { label: 'CIDR', value: IpRangeType.CIDR },
                {
                  label: 'Range',
                  value: IpRangeType.RANGE,
                },
              ]}
              value={blockRuleSelected.ipRangeType}
              placeholder="Please select type"
              onChange={handleSelectTypeIpRange}
              disabled={userPlanFree || userPlanPremium}
            />
          </Box>
        </Collapsible>
        {(criteria !== CriteriaType.IpRanges && !id) &&
          <Box width="200px">
            <Select
              label="Select type"
              options={[
                { label: 'Manual', value: IPTypes.Manual },
                { label: 'Upload CSV', value: IPTypes.Upload },
              ]}
              value={inputIPMethod}
              onChange={handleInputMethodChange}
            />
          </Box>
        }

        <div>
          {ipRangeType === IpRangeType.RANGE && criteria === CriteriaType.IpRanges ? (
            <>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    requiredIndicator
                    autoComplete=""
                    label="Start IP"
                    placeholder="E.g: 192.168.1.1"
                    value={blockRuleSelected.ipStart}
                    onChange={handleChangeIpRangeValue('ipStart')}
                    onBlur={handleOnBlurIpRangeValue}
                  />
                  <TextField
                    requiredIndicator
                    autoComplete=""
                    label="End IP"
                    placeholder="E.g: 192.168.1.254"
                    value={blockRuleSelected.ipEnd}
                    onChange={handleChangeIpRangeValue('ipEnd')}
                    onBlur={handleOnBlurIpRangeValue}
                  />
                </FormLayout.Group>
              </FormLayout>
              <div style={{ marginTop: '4px' }}>
                <InlineError message={useError.errorSelected?.error as string} fieldID="" />
              </div>
            </>
          ) : (
            <>
              {inputIPMethod === IPTypes.Manual && (
                <TextField
                  requiredIndicator
                  autoComplete="off"
                  label={label}
                  error={useError.errorSelected?.error}
                  onBlur={() => {
                    if (blockRuleSelected.id && blockRuleSelected.ipAddress?.includes(',')) {
                      useError.handleErrorRule("You can't edit multiple IP address");
                    }
                    if (!blockRuleSelected.ipAddress) {
                      useError.handleErrorRule('IP address is required');
                    }
                  }}
                  value={blockRuleSelected.ipAddress}
                  placeholder={placeholder}
                  onChange={handleChange}
                  helpText="You can enter multiple IPs at the same time, each IP separated by a comma."
                  disabled={(userPlanFree || userPlanPremium) && criteria === CriteriaType.IpRanges}
                />
              )}

              {inputIPMethod === IPTypes.Upload && (
                <div className='mt-8'>
                  <DropZoneCustom
                    fileDefault={fileDefault ? fileDefault.name : ''}
                    label=""
                    onChangeFile={handleFileUpload}
                    onRemove={() => {
                      dispatch(blockListSlice.actions.handleChangeFileDefault((null)))
                      dispatch(blockListSlice.actions.handleErrorCSV(''))
                    }}
                  />
                  <div className='mt-4'>
                    <Text variant="bodyMd" as="p" tone="subdued">Download <Link onClick={() => window.open(getTemplateCSVIp && getTemplateCSVIp.data?.url, '_blank')}>the sample file</Link> for format.</Text>
                  </div>
                  {errorCSV !== '' && <InlineError message={errorCSV} fieldID="" />}
                </div>
              )}
            </>
          )}

          {ipRangeType !== IpRangeType.RANGE && (
            <InlineStack gap={'100'} wrap={true}>
              {uniq(blockRuleSelected.ipAddress?.split(',').map((item) => item.trim()) || [])
                .filter((item) => item !== '')
                .splice(0, 500)
                .map((ip) => {
                  return (
                    <div
                      className={
                        !!data?.failureRules.find((item) => item.errRule.ipAddress === ip)
                          ? 'mt-8 mr-8 Polaris-Tag background-red'
                          : 'mt-8 mr-8 Polaris-Tag'
                      }
                      key={ip}
                    >
                      <h1 title={ip} className={'Polaris-Tag__TagText'}>
                        {ip}
                      </h1>
                      <button className="Polaris-Tag__Button" onClick={() => onRemove(ip)}>
                        <div>X</div>
                      </button>
                    </div>
                  );
                })}
            </InlineStack>
          )}
        </div>
      </BlockStack>
    </div>
  );
};

export default memo(IpAddress);
