import BlockPageStoreFront from '@/components/BlockPageStoreFront';
import Layout from '@/components/layout';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { Embedded } from '@/constants/enum';
import { deepObjectEqual } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { handleBlockingTemplateId } from '@/redux/slice/dataSettings.slice';
import settingsSlice, {
  cssBackupSelector,
  cssSelector,
  selectedTabSelector,
  templateIdSelector,
  templateSavedSelector,
  templateSelector,
} from '@/redux/slice/settings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomCSS from './component/CustomCSS';
import Editor from './component/Editor';
import Template from './component/Template';
import { PreviewStyled } from './styled';
import { ContextualBar } from '@/components/ContextualBar';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import useContextual from '@/components/ContextualBar/HideContextual';

// const btnGroup = [
//   {
//     icon: DesktopIcon,
//     type: 'desktop',
//   },
//   // {
//   //   icon: MobileIcon,
//   //   type: 'mobile',
//   // },
// ];

const Preview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isShowContextualBar = useSelector(isShowSelector);
  const contextual = useContextual();

  const selectedTab = useSelector(selectedTabSelector);
  const template = useSelector(templateSelector);
  const templateSaved = useSelector(templateSavedSelector);
  const templateId = useSelector(templateIdSelector);
  const css = useSelector(cssSelector);
  const cssBackup = useSelector(cssBackupSelector);
  // const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const [updateCSS] = apiCaller.useUpdateCustomizeCSSMutation();
  const [uploadImage] = apiCaller.useUploadImageMutation();
  const [uploadTemplate, { isLoading }] = apiCaller.useUpdateTemplateMutation();
  // const [trackAction] = apiCaller.useTrackActionMutation();
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery(undefined, { skip: isSkip });

  const [files, setFiles] = useState<{ image: null | File; backgroundImage: null | File }>({
    image: null,
    backgroundImage: null,
  });
  const [focusId, setFocusId] = useState('');
  const [activeButtonIndex] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(settingsSlice.actions.handleSelectedTab(selectedTabIndex)),
    [dispatch],
  );

  // const handleOpenTestBlock = () => {
  //   window.open(dataSettings?.settings.testBlockUrl);
  // };

  // const removeWaterMark = () => {
  //   const sendText = 'I want to remove brand mark in the blocking page';
  //   try {
  //     $crisp.push(['do', 'chat:open']);
  //     $crisp.push(['do', 'message:send', ['text', sendText]]);
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   trackAction('remove_water_mark');
  // };

  const tabs = useMemo(() => {
    return [
      {
        id: 'template',
        content: 'Template',
        fields: <Template />,
      },
      {
        id: 'style',
        content: 'Style',
        fields: <Editor files={files} setFiles={setFiles} setFocusId={setFocusId} />,
      },
      {
        id: 'css',
        content: 'Custom CSS',
        fields: <CustomCSS />,
      },
    ];
  }, [files]);

  const isDisableButton = css === cssBackup && deepObjectEqual(template, templateSaved) && !files.image && !files.backgroundImage;

  const handleUpdateTemplate = () => {
    const update = async () => {
      try {
        let newTemplate = {
          ...template,
        };

        if (files.image) {
          const formDataLogo = new FormData();
          formDataLogo.append('image', files.image);
          const logo = await uploadImage(formDataLogo);

          if ('data' in logo) {
            newTemplate = {
              ...newTemplate,
              logoImage: {
                ...newTemplate?.logoImage,
                value: logo.data.url,
              },
            };
          }
        }

        if (files.backgroundImage && Enum.BackgroundType.Image) {
          const formDataBackground = new FormData();
          formDataBackground.append('image', files.backgroundImage);
          const background = await uploadImage(formDataBackground);
          if ('data' in background) {
            newTemplate = {
              ...newTemplate,
              background: {
                ...newTemplate.background,
                value: background.data.url,
              },
            };
          }
        }
        await uploadTemplate({
          properties: newTemplate,
          templateId: templateId,
        });
        await updateCSS({
          cssCode: css,
        });
        setFiles({
          image: null,
          backgroundImage: null,
        });

        dispatch(
          settingsSlice.actions.handleTemplateSaved({
            ...newTemplate,
          }),
        );
        dispatch(
          settingsSlice.actions.handleTemplate({
            ...newTemplate,
          }),
        );
        dispatch(settingsSlice.actions.handleCssBackup(css));
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      } catch (e) {
        console.log(e);

        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Save failure',
            error: false,
          }),
        );
      }
    };
    update();
  };

  const handleDiscard = () => {
    dispatch(
      settingsSlice.actions.handleInput({
        titleColor: templateSaved.title.color,
        backgroundColor: templateSaved.background.value,
        contentColor: templateSaved.description.color,
        superTitleColor: templateSaved.superTitle.color,
        backgroundGradientFrom: templateSaved.background.colorFrom || '',
        backgroundGradientTo: templateSaved.background.colorTo || '',
      }),
    );
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...templateSaved,
      }),
    );
    dispatch(settingsSlice.actions.handleCss(cssBackup));

    dispatch(handleBlockingTemplateId(fetchDataSettings?.data?.settings?.blockingTemplateId!));
  };

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
          primaryAction: {
            content: 'Save',
            disabled: isDisableButton,
            loading: isLoading,
            onAction: handleUpdateTemplate,
          },
          secondaryActions: [
            {
              content: 'Discard',
              onAction: handleDiscard,
              disabled: isDisableButton,
            },
          ],
        }
      : null;

  const handleBackAction = () => {
    if (isShowContextualBar && config.embedded === Embedded.LIVE) {
      contextual.hide();
    } else {
      navigate(PATH.SETTINGS);
    }
  };

  return (
    <Layout
      layoutProps={{
        title: 'Blocking page',
        subtitle: 'Customize the blocking page to display restrictions.',
        backAction: { content: '', onAction: handleBackAction },
        ...layoutSettings,
      }}
      isFullWidth
    >
      <ContextualBar
        disabledSave={isDisableButton}
        loadingSave={isLoading}
        onSave={handleUpdateTemplate}
        value={template}
        disabledDiscard={isDisableButton}
        onDiscard={handleDiscard}
      />
      <PreviewStyled>
        <div className="preview-container">
          <div className="preview-fields Polaris-Box">
            <Tabs tabs={tabs} fitted selected={selectedTab} onSelect={handleTabChange}>
              <div className="preview-fields-content">
                <div>{tabs[selectedTab].fields}</div>
                {/* {dataSettings?.settings.user.plan === Enum.UserPlan.FREE && dataSettings.settings.showWatermark ? (
                  <div className="preview-remove-watermark">
                    <BoldText>Remove Blockify branding</BoldText>
                    <div className="mb-8">
                      <RegularText>
                        If you like to remove “Powered by Blockify” brand logo for free, please contact us!
                      </RegularText>
                    </div>
                    <Button variant="primary" onClick={removeWaterMark}>
                      Contact us
                    </Button>
                  </div>
                ) : null} */}
              </div>
            </Tabs>
          </div>

          <div className="preview-blocking-page">
            <div className={activeButtonIndex === 0 ? 'preview-blocking-page-fullscreen' : 'preview-blocking-page-mobile'}>
              <Card>
                {/* <div className="d-flex flex-center mt-8 mb-8">
                  {btnGroup.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        icon={item.icon}
                        variant={activeButtonIndex === index ? 'primary' : undefined}
                        onClick={() => handleButtonClick(index)}
                      />
                    );
                  })}
                </div> */}
                {/* <div className="btn-preview">
                  <Button icon={ViewIcon} onClick={handleOpenTestBlock}></Button>
                </div> */}
                {/* <Divider /> */}
                <BlockPageStoreFront focusId={focusId} />
              </Card>
            </div>
          </div>
        </div>
      </PreviewStyled>
    </Layout>
  );
};

export default memo(Preview);
