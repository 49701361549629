import styled from 'styled-components';

interface GetStartStyledProps {}

export const GetStartStyled = styled.div<GetStartStyledProps>`
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  left: 20px;

  .btn-get-started {
    display: flex;
    flex-direction: row-reverse;
    &:hover {
      background-color: #e45530;
    }
    .Polaris-Icon {
      margin-right: 8px;
    }
    background: rgba(254, 87, 34, 1);
    padding: 16px 20px;
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.25s ease-in;

    h2 {
      color: white;
    }
    .Polaris-Icon__Svg {
      fill: white; /* Màu bên trong biểu tượng */
      stroke: white; /* Màu đường viền */
      /* stroke-width: 1.1;  Độ dày đường viền */
    }

    .Polaris-Spinner svg {
      fill: white;
    }
  }

  .popup-setup {
    max-width: calc(100vw - 32px);
    width: 400px;
    height: 100%;
  }
`;

interface PopupSetupStyledProps {}
export const PopupSetupStyled = styled.div<PopupSetupStyledProps>`
  padding: 16px;
  height: 430px;
  width: 350px;
  max-width: 100%;

  .progress-bar-wrap {
    width: 200px;
    cursor: pointer;
    .Polaris-ProgressBar__Indicator {
      background: rgba(254, 87, 34, 1);
    }
  }

  .set-item {
    .open-collapse,
    &:hover {
      background: rgba(240, 240, 240, 1);
    }
    transition: all 0.25s ease-in;
    cursor: pointer;
    display: flex;
    align-items: start;
    gap: 10px;
    border-radius: 6px;
    padding: 10px;
  }

  .open-collapse {
    background: rgba(240, 240, 240, 1);
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px dotted gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.3s;

    &:hover {
      border-color: #000;
      border: 2px solid #000;
    }
  }
`;
