import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Ga4Event } from '@/constants/enum';
// Define the initial state using that type
const initialState = {
  showRecommendedApps: true,
  gaFour: {
    homePage: false,
    checkoutRules: false,
    pareto: false,
    synctrack: false,
    return: false,
    ot: false,
    estimated: false,
    cookies: false,
    fbPixel: false,
    trustify: false,
    pricingPlan: false,
    generalSetting: false,
    [Ga4Event.ImpressAdoric]: false,
    [Ga4Event.ImpressFacebookPixel]: false,
    [Ga4Event.ImpressHyperSKU]: false,
    [Ga4Event.ImpressTrustify]: false,
  },
  showPopoverGetStared: false,
  hasClickGetStared: false,
};

const sessionSlice = createSlice({
  name: 'session',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleShowRecommendedApps: (state, action: PayloadAction<boolean>) => {
      state.showRecommendedApps = action.payload;
    },
    handleGaFour: (
      state,
      action: PayloadAction<{
        key: keyof typeof state.gaFour;
      }>,
    ) => {
      state.gaFour[action.payload.key] = true;
    },
    handleShowPopoverGetStared: (state, action: PayloadAction<boolean>) => {
      state.showPopoverGetStared = action.payload;
    },

    handleHasClickGetStared: (state, action: PayloadAction<boolean>) => {
      state.hasClickGetStared = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const showRecommendedAppsSelector = createSelector(
  (state: RootState) => state.sessionStorage,
  (state) => state.showRecommendedApps,
);

export const gaFourSelector = createSelector(
  (state: RootState) => state.sessionStorage,
  (state) => state.gaFour,
);

export const showPopoverGetStaredSelector = createSelector(
  (state: RootState) => state.sessionStorage,
  (state) => state.showPopoverGetStared,
);

export const hasClickGetStaredSelector = createSelector(
  (state: RootState) => state.sessionStorage,
  (state) => state.hasClickGetStared,
);

export const { handleShowPopoverGetStared, handleHasClickGetStared } = sessionSlice.actions;

export default sessionSlice;
