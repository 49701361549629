import { IDScrollIntoView, UserPlan } from '@/constants/enum';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { loadingSelector } from '@/redux/slice/dataSettings.slice';
import { ISettingToggle } from '@/types/components';
import { Badge, Card, InlineStack, Link, SkeletonDisplayText, Text } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import CustomBadge from '../CustomBadge';
import { CustomTooltip } from '../CustomTooltip';
import RegularText from '../RegularText';
import Switch from '../Switch';
import { CustomSettingSwitchStyled } from './styled';

const CustomSettingSwitch = ({ ...props }: ISettingToggle.IProps) => {
  const scope = useScope();
  const isLoading = useSelector(loadingSelector);

  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = userPlans();

  const isUserPlanFree = () => props.minActivePlan === UserPlan.PREMIUM && userPlanFree;
  const isUserPlanEnterprise = () => props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium);
  const isUserPlanEnterpriseWithoutShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && !shopifyPlanPlus;
  const isUserPlanEnterpriseWithShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && shopifyPlanPlus;
  const isUserPlanShopifyPlus = () => props.minActivePlan && props.minActivePlan === UserPlan.SHOPIFYPLUS && userPlanFree;

  const condition =
    isUserPlanFree() ||
    isUserPlanEnterprise() ||
    isUserPlanEnterpriseWithoutShopifyPlus() ||
    isUserPlanEnterpriseWithShopifyPlus() ||
    isUserPlanShopifyPlus();

  const renderBadge = () => {
    const badgeConfig = [
      {
        condition: isUserPlanFree,
        idScroll: IDScrollIntoView.Pricing_premium,
        text: 'Premium plan or higher',
      },
      {
        condition: isUserPlanEnterpriseWithoutShopifyPlus,
        idScroll: IDScrollIntoView.Pricing_enterprise,
        text: 'Enterprise plan',
      },
      {
        condition: isUserPlanEnterpriseWithShopifyPlus,
        idScroll: IDScrollIntoView.Pricing_enterprise,
        text: 'Enterprise plan or higher',
      },
      {
        condition: isUserPlanShopifyPlus,
        idScroll: IDScrollIntoView.Pricing_shopify_plus,
        text: 'Shopify plus plan',
      },
    ];

    const matchedBadge = badgeConfig.find(({ condition }) => condition());

    return matchedBadge ? (
      <div>
        <CustomBadge scrollToID={matchedBadge.idScroll} tone="info">
          {matchedBadge.text}
        </CustomBadge>
      </div>
    ) : null;
  };

  return (
    <CustomSettingSwitchStyled>
      <Card>
        <InlineStack align="space-between" wrap={false} gap="200" blockAlign="start">
          <div>
            <div className="toggle-settings-title">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Text as="h5" variant="headingMd">
                  {props.title}
                  <div className="ml-4">
                    <InlineStack gap="100">
                      <Badge tone={props.settingToggleProps.enabled ? 'success' : 'new'}>
                        {props.settingToggleProps.enabled ? 'On' : 'Off'}
                      </Badge>
                      {renderBadge()}
                    </InlineStack>
                  </div>
                </Text>
                {props.oneColumn ? (
                  <div className="toggle-btn">
                    {isLoading && props.settingToggleProps.action?.loading ? (
                      <SkeletonDisplayText size="small" />
                    ) : condition ? (
                      <Switch hasTooltip={false} onSwitch={() => {}} isActive={false} isLoading />
                    ) : (
                      <Switch
                        hasTooltip={true}
                        onSwitch={() => props.settingToggleProps.action?.onAction}
                        isLoading={props.settingToggleProps.action?.loading || scope.isViewOnly}
                        isActive={props.settingToggleProps.enabled}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            {props.children ? (
              <div className="mt-8">
                {typeof props.children === 'string' ? <RegularText>{props.children}</RegularText> : props.children}
              </div>
            ) : null}
          </div>

          {!props.oneColumn ? (
            <div className="toggle-btn">
              {condition ? (
                <Switch hasTooltip={false} onSwitch={() => {}} isActive={false} isLoading />
              ) : (
                <>
                  {(props.disabled && !props.contentTooltipWithLink) || !props.disabled ? (
                    <>
                      <Switch
                        hasTooltip={true}
                        isActive={props.settingToggleProps.enabled}
                        isLoading={props.settingToggleProps.action?.loading || scope.isViewOnly || props.disabled}
                        onSwitch={() => props.settingToggleProps.action?.onAction?.()}
                      />
                    </>
                  ) : (
                    <CustomTooltip
                      activator={
                        <div className="btn-toggle">
                          <RegularText>{props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}</RegularText>
                        </div>
                      }
                    >
                      <Link onClick={props.onClick}>{props.contentTooltipWithLink}</Link>
                    </CustomTooltip>
                  )}
                </>
              )}
            </div>
          ) : null}
        </InlineStack>
        {props.content ? <div className="mt-16">{props.content}</div> : null}
      </Card>
    </CustomSettingSwitchStyled>
  );
};

export default memo(CustomSettingSwitch);
