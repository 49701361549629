import { images } from '@/asset';
import { dashboardImage } from '@/asset/images/dashboard';
import BannerReviewApp from '@/components/BannerReviewApp';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CardForeverFree from '@/components/CardForeverFree';
import FlashSaleCard from '@/components/FlashSaleCard';
import GetStarted from '@/components/GetStarted';
import CustomLayout from '@/components/layout';
import PromotionsCard from '@/components/PromotionsCard';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { Ga4Event } from '@/constants/enum';
import { link } from '@/constants/link';
import { useGa4 } from '@/hooks/useGa4';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import {
  bannerSelector,
  handleVisibleBanner,
} from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleShowModalSearch } from '@/redux/slice/home.slice';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon, SearchIcon } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Overview from './overview';
import RecommendedApps from './recommendedApps';
import { HomePageStyled } from './styled';

function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleGa4 } = useGa4();
  const { userPlanEnterprise, shopifyPlanPlus, planAppliedList, userPlanFree } = UserPlans();
  const banner = useSelector(bannerSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const gaFour = useSelector(gaFourSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const [trackAction] = apiCaller.useTrackActionMutation();

  const recommendAppRef = useRef(null);

  const [showBannerFromToDate, setShowBannerFromToDate] = useState(true);
  const [showBannerReferApp, setShowBannerReferApp] = useState(false);

  const isShowBannerCampaign =
    !userPlanEnterprise && !shopifyPlanPlus && banner.showFlashSaleCard && showBannerFromToDate && !planAppliedList?.length;

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(dataSettings?.settings.user.lastInstalledDate || dataSettings?.settings.user.installedDate);

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowBannerFromToDate(true);
    } else {
      setShowBannerFromToDate(false);
    }
  }, [dataSettings?.settings.user.installedDate, dataSettings?.settings.user.lastInstalledDate]);

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: 'Contact support (24/7)',
        description: 'Get in touch with the support team. The response time on live chat under a few hours.',
        onAction: () => {
          const sendText = 'I need assistance with using Blockify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: 'Chat with us',
        icon: ChatIcon,
      },
      {
        title: 'Read user guideline',
        description: 'Step-by-step instruction articles to guide you in setting up rules in the easiest way.',
        onAction: () => {
          window.open('https://docs.ipblocker.io/');
        },
        btnContent: 'Read user guideline',
        icon: BookIcon,
      },
      {
        title: 'Discover our website',
        description: 'Explore our website to find the new updates of our brand.',
        onAction: () => {
          window.open('https://www.ipblocker.io/');
        },
        btnContent: 'Visit website',
        icon: GlobeAsiaFilledIcon,
      },
    ];
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour.homePage) {
            dispatch(
              sessionSlice.actions.handleGaFour({
                key: 'homePage',
              }),
            );
            handleGa4(Ga4Event.ImpressPareto);
            handleGa4(Ga4Event.SynctrackBannerImpression);
            handleGa4(Ga4Event.ImpressCookies);
            handleGa4(Ga4Event.ImpressCheckout);
          }
        }
      });
    });

    if (recommendAppRef.current) {
      observer.observe(recommendAppRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (recommendAppRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(recommendAppRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour.homePage]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (banner.referCheckoutRulesBanner && location.pathname === PATH.DEFAULT.pathname) {
        setShowBannerReferApp(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [banner.referCheckoutRulesBanner, location.pathname]);

  const handleRedirectToCheckoutRuleApp = () => {
    window.open(
      'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=dashboardinapp',
      '_blank',
    );
    trackAction('banner-set-up-checkout-rule');
  };

  return (
    <HomePageStyled>
      <GetStarted />
      <CustomLayout
        layoutProps={{
          title: `Hello ${dataSettings?.settings?.user?.businessName || config.shop.split('.')[0]},`,
          secondaryActions: (
            <>
              <InlineStack gap="150">
                <Button onClick={() => navigate(PATH.ANALYTICS)}>Visitor Dashboard</Button>
                <Button onClick={() => dispatch(handleShowModalSearch(true))} icon={SearchIcon}>
                  Search settings
                </Button>
              </InlineStack>
            </>
          ),
        }}
        isVisibleHeader={!dataSettings?.url}
      >
        <div className="mt-16">
          <Text variant="bodyLg" as="span">
            Welcome to Blockify 🎉
          </Text>
        </div>

        <div className='mt-16'>
          <Collapsible
            id="banner-lunar-new-year"
            open={banner.lunarNewYear}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(handleVisibleBanner({
                    key: "lunarNewYear",
                    value: false,
                  }));
                }}
                tone="info"
                title="Lunar New Year Holiday Notice"
              >
                <div className="d-flex flex-column">
                  <RegularText>
                    Our team will be out of the office for the Lunar New Year from <b>January 25th</b> to <b>February 2nd, 2025</b>. During this time, there may be delays in our responses. We expect to reply to your messages by <b>Monday, February 3rd</b>, when we return.
                  </RegularText>
                  <RegularText>
                    For limited support, our customer support agents will be available from <b>January 25th</b> to <b>February 2nd</b>.
                  </RegularText>
                  <RegularText>
                    Thank you for your understanding!
                  </RegularText>
                  <div className='mt-4'>
                    <BoldText>
                      Happy Lunar New Year! &#127881;
                    </BoldText>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        </div>

        {checkEmbeddedStatus.data && (
          <Collapsible
            id="banner-check-embedded-app"
            open={banner.noticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(handleVisibleBanner({
                    key: "noticeEmbeddedApp",
                    value: false,
                  }));
                }}
                tone="warning"
                title="Action required: Activate app embed"
              >
                <div className="d-flex flex-column">
                  <RegularText>
                    Go to <b>Shopify Theme Editor</b> &gt; Turn on <b>Blockify app</b> on the App Embeds section.
                  </RegularText>
                  <div className="mt-8">
                    <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                      Activate app now
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        )}

        <BannerReviewApp />

        <CardForeverFree />

        <div className="mt-16">
          <Overview />
        </div>

        <div className="mt-16">
          <Collapsible
            id="gift_box"
            open={isShowBannerCampaign}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <FlashSaleCard />
          </Collapsible>
        </div>

        <div className="card-info">
          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {infoAndSupport.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="card-container">
                    <div>
                      <div className="d-flex">
                        <Icon source={item.icon} />
                        <div className="ml-8">
                          <BoldText>{item.title}</BoldText>
                        </div>
                      </div>
                      <div className="card-text">
                        <RegularText>{item.description}</RegularText>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Button onClick={item.onAction}>{item.btnContent}</Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>

        {/* Synctrack */}
        <div className="mt-16">
          <PromotionsCard />
        </div>

        {/* Checkout rules */}
        {userPlanFree && (
          <div className="mt-16">
            <BannerTemplate
              src={dashboardImage.pareto}
              isOpen={banner.promotePareto}
              onDismiss={() => dispatch(handleVisibleBanner({
                key: 'promotePareto',
                value: false
              }))}
            >
              <BoldText>Prevent frauds & protect your revenue by setting min/max purchase limit</BoldText>
              <div className="mt-8">
                <RegularText>
                  Pareto ‑ Order Limit Quantity help you control the quantity of products customer can purchase, improving
                  inventory management and prevent frauds. Set up order limit base on quantity, time, and customers. Minimize
                  suspicious bulk purchases. 24/7 live agent support.
                </RegularText>
              </div>
              <div className="mt-16">
                <Button
                  onClick={() => {
                    if (!gaFour.pareto) {
                      handleGa4(Ga4Event.ParetoClick);
                    }
                    window.open(
                      'https://apps.shopify.com/pareto-limit-purchase?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  100% FREE APP
                </Button>
              </div>
            </BannerTemplate>
          </div>
        )}
        <div className="app-recommended" ref={recommendAppRef}>
          <RecommendedApps />
        </div>
      </CustomLayout>
    </HomePageStyled>
  );
}

export default memo(HomePage);
