import Layout from '@/components/layout';
import { Enum } from '@/constants';
import { Badge } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { CheckoutRulesStyled } from './styled';
// import HidePayment from './hide-payment';
import HidePayment from '../Hide-payment';

type MethodHide = 'payment' | 'shipping';

const CheckoutRules = () => {
  const [selectedMethodHide, setSelectedMethodHide] = useState<MethodHide>('payment');

  useEffect(() => {
    const storedMethodHide = sessionStorage.getItem('selectedMethodHide') as MethodHide | null;
    setSelectedMethodHide(storedMethodHide ? storedMethodHide : Enum.CheckoutRules.payment);
  }, []);

  // const handleTabClick = (methodHide: MethodHide) => {
  //   setSelectedMethodHide(methodHide);
  //   sessionStorage.setItem('selectedMethodHide', methodHide);
  // };

  // const tabsList = [
  //   {
  //     label: 'Hide Payment methods',
  //     pageActive: selectedMethodHide === Enum.CheckoutRules.payment,
  //     onclick: () => handleTabClick(Enum.CheckoutRules.payment),
  //   },
  //   {
  //     label: 'Hide Shipping methods',
  //     pageActive: selectedMethodHide === Enum.CheckoutRules.shipping,
  //     onclick: () => handleTabClick(Enum.CheckoutRules.shipping),
  //   },
  // ];

  const hideMethods = {
    payment: <HidePayment />,
    shipping: <div>Shipping</div>,
  };

  return (
    <Layout
      layoutProps={{
        title: 'Checkout Rules',
        titleMetadata: <Badge tone="enabled">Beta</Badge>,
      }}
    >
      <CheckoutRulesStyled className="checkout-rules-container">
        {/* <div className="btn-group-tabs">
          {tabsList.map((item) => (
            <Button onClick={item.onclick} key={item.label} size="large" id={item.pageActive ? 'page-active' : ''}>
              {item.label}
            </Button>
          ))}
        </div> */}

        {/* <div className="mb-16">
          <Text variant="headingMd" as="h2">
            Hide {capitalizeFirstLetter(selectedMethodHide || '')} methods
          </Text>
        </div> */}

        {hideMethods[selectedMethodHide]}
      </CheckoutRulesStyled>
    </Layout>
  );
};

export default CheckoutRules;
