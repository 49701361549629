import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSomeDaysAgo } from '@/helpers';
import { IDScrollIntoView } from '@/constants/enum';
// Define the initial state using that type
export interface IDateTime {
  startDate: Date;
  endDate: Date;
}
const now = new Date();
const initialState: {
  currentStep: number;
  titleBtnDatePicker: string;
  datePicker: IDateTime;
  showModalSearch: boolean;
  idScrollIntoView: IDScrollIntoView;
} = {
  currentStep: 0,
  titleBtnDatePicker: 'Last 7 days',
  datePicker: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
  },
  showModalSearch: false,
  idScrollIntoView: IDScrollIntoView.None,
};

const homeSlice = createSlice({
  name: 'home',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
    handleDatePicker: (state, action: PayloadAction<IDateTime>) => {
      state.datePicker = action.payload;
    },
    handleShowModalSearch: (state, action: PayloadAction<boolean>) => {
      state.showModalSearch = action.payload;
    },
    handleIdScrollIntoView: (state, action: PayloadAction<IDScrollIntoView>) => {
      state.idScrollIntoView = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const currentStepSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.currentStep,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.titleBtnDatePicker,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.datePicker,
);
export const showModalSearchSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.showModalSearch,
);
export const idScrollIntoViewSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.idScrollIntoView,
);

export const { handleShowModalSearch, handleIdScrollIntoView } = homeSlice.actions;

export default homeSlice;
