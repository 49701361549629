import { images } from '@/asset';
import { PATH } from '@/constants';
import { ActionType, BlockCheckoutType, CriteriaType, ListType, NameType, SubKeyHelpCenter } from '@/constants/enum';
import options from '@/constants/options';
import UserPlans from '@/hooks/userPlans';
import blockCheckoutSlice, { tabsSelector } from '@/redux/slice/blockCheckout.slice';
import blockCheckoutAccessSlice, { settingSelector } from '@/redux/slice/blockCheckoutAccess';
import helpCenterSlice from '@/redux/slice/helpCenter.slice';
import { Badge, Card, InlineGrid, MediaCard, Tabs, Text } from '@shopify/polaris';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IProps {
  onCloseGuide: () => void;
}

const listRuleOptions = options.configRules.filter((rule) => !rule.hiddenBlockAccess);

const BlockCheckoutTabs = ({ onCloseGuide }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tab = useSelector(tabsSelector);
  const settings = useSelector(settingSelector);
  const { shopifyPlanPlus } = UserPlans();
  const isShopifyPlus = process.env.REACT_APP_ENV === 'production' ? shopifyPlanPlus : true;
  // const isShopifyPlus = false;
  const handleModalClick = useCallback(
    (id: string) => {
      dispatch(blockCheckoutAccessSlice.actions.handleErrorRule([]));
      if (id === CriteriaType.ISP) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputIsp(''));
      }
      if (id === CriteriaType.Product) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputProduct(''));
      }
      navigate(PATH.BLOCK_CHECKOUT_ACCESS_PAGE);

      const payload = {
        ...settings,
        isActive: true,
        id: undefined,
        linkRedirect: '',
        shortUrl: '',
        referUrl: '',
        shortReferUrl: '',
        note: '',
        collectionId: [],
        state: [],
        city: [],
        country: [],
        ipAddress: '',
        ispName: [],
        ispCode: [],
        productId: [],
        pageId: [],
        deviceType: '',
        osName: '',
        browserName: [],
        criteria: id,
        type: ActionType.Block,
        priority: ListType.BlackList,
        errorMessage: '',
      };

      dispatch(blockCheckoutAccessSlice.actions.handleSetting(payload));
      dispatch(blockCheckoutAccessSlice.actions.handleSettingBackup(payload));
    },
    [dispatch, navigate, settings],
  );
  const tabs = useMemo(() => {
    return [
      {
        id: 'block_checkout_access',
        content: 'Block checkout access',
        disabled: !isShopifyPlus,
        icon: (
          <button
            aria-disabled={!isShopifyPlus ? 'true' : undefined}
            className={tab === 0 ? 'Polaris-Tabs__Tab Polaris-Tabs__Tab--active fw-550' : 'Polaris-Tabs__Tab fw-550'}
          >
            {' '}
            <Text as="span" variant="bodySm">
              Block checkout access <Badge tone="info">Shopify Plus only</Badge>
            </Text>{' '}
          </button>
        ),
        element: (
          <InlineGrid columns={{ xl: 4, lg: 4, md: 4, sm: 2, xs: 2 }} gap={{ xs: '100', md: '200' }}>
            {listRuleOptions.map((item, index) => {
              return (
                <Card key={index} padding={'0'}>
                  <div key={index} className="add-rule-container" onClick={() => handleModalClick(item.id)}>
                    <img className="add-rule-img" src={item.src} alt={item.description} />
                    <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                      {`Block ${item.description}`}
                    </Text>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        ),
      },
      {
        id: 'block_checkout_fields',
        content: 'Block checkout fields',
        element: (
          <MediaCard
            title="Block checkout fields"
            primaryAction={{
              content: 'Create rule',
              onAction: () => {
                onCloseGuide();
                navigate(PATH.BLOCK_CHECKOUT_PAGE);
                dispatch(blockCheckoutSlice.actions.handleCondition(BlockCheckoutType.Email));
                dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.BlockCheckoutEmail));
                dispatch(
                  blockCheckoutSlice.actions.handleBlockRuleSelected({
                    criteria: BlockCheckoutType.Email,
                    isActive: true,
                    value: '',
                    fieldName: NameType.FullName,
                  }),
                );
                dispatch(
                  blockCheckoutSlice.actions.handleBlockRuleBackupSelected({
                    criteria: BlockCheckoutType.Email,
                    isActive: true,
                    value: '',
                    fieldName: NameType.FullName,
                  }),
                );
              },
            }}
            description="Prevent fraudulent checkouts with specific conditions."
          >
            <div className="d-flex flex-center">
              <img
                alt=""
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  padding: '10px 0',
                }}
                src={images.preview_error_checkout}
              />
            </div>
          </MediaCard>
        ),
      },
    ];
  }, [dispatch, handleModalClick, isShopifyPlus, navigate, onCloseGuide, tab]);
  return (
    <Card>
      <Tabs
        tabs={tabs}
        selected={tab}
        onSelect={(index) => {
          dispatch(blockCheckoutSlice.actions.handleTabs(index));
        }}
      >
        {tabs[tab]?.element}
      </Tabs>
    </Card>
  );
};

export default memo(BlockCheckoutTabs);
