import { Enum } from '@/constants';
import { ISettings } from '@/types/components';
import styled from 'styled-components';

interface Props {
  template: ISettings.CustomTemplate;
  focusId: string;
  css: string;
  smallScreen?: boolean;
}
export const BlockPageStyled = styled.div<Props>`
  position: relative;
  width: 100%;

  #blockify---container {
    aspect-ratio: 2 / 1;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    font-size: 16px;
    position: relative;
    overflow: hidden;
  }
  ${(props) => props.css}

  #blockify---container:after {
    background-image: ${(props) =>
      props.template.background.type === Number(Enum.BackgroundType.Image)
        ? `url(${props.template.background.value})`
        : `linear-gradient(to right,${props.template.background.colorFrom}, ${props.template.background.colorTo})`} !important;
    background-color: ${(props) =>
      Number(props.template.background.type) === Number(Enum.BackgroundType.Color)
        ? props.template.background.value
        : 'white'} !important;
  }
  #blockify---container {
    background-image: ${(props) =>
      props.template.background.type === Number(Enum.BackgroundType.Image)
        ? `url(${props.template.background.value})`
        : `linear-gradient(to right,${props.template.background.colorFrom}, ${props.template.background.colorTo})`} !important;
    background-color: ${(props) =>
      Number(props.template.background.type) === Number(Enum.BackgroundType.Color)
        ? props.template.background.value
        : 'white'} !important;
  }
  #blockify-block-superTitle {
    color: ${(props) => props.template.superTitle.color} !important;
  }
  #blockify-block-title {
    color: ${(props) => props.template.title.color} !important;
  }
  #blockify-block-description {
    color: ${(props) => props.template.description.color} !important;
  }
  label {
    display: block;
    line-height: normal;
  }
  .watermark-container {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    img {
      margin: 0 8px;
    }
  }

  .card-btn-device {
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  .is-selected {
    background-color: #e3e3e3;
  }

  label[for=${(props) => props.focusId}] {
    border: 1px dotted #005bd3;
  }
  ${(props) =>
    props.smallScreen
      ? `
      #blockify---container {
        font-size : 24px;
      }
      .Polaris-Link {
        font-size : 36px
      }
      .watermark-container {
        div {
        font-size : 120%
    
        }
      }
      .watermark-container img {
        height :  48px;
        width : 48px;
        magin : 0 5px
      }
`
      : ''}
`;

export const SprintContainerStyled = styled.div`
  width: 100%;
  height: 80vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
