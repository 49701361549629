import styled from 'styled-components';

export const CustomSettingSwitchStyled = styled.div`
  .Polaris-Badge {
    margin-left: 4px;
  }
  .toggle-btn {
  }
  .toggle-settings-title {
    display: flex;
    align-items: center;
    line-height: 1.75rem;
    .tone-yellow {
      .Polaris-Badge {
        background-color: #ffea8a;
      }
    }
    .tone-orange {
      .Polaris-Badge {
        background-color: #ffc694;
      }
    }
    .Polaris-Text--root {
      display: flex;
    }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
      width: 2rem;
    }
  }
  .Polaris-SkeletonDisplayText__DisplayText {
    width: 4.5rem;
  }
  .ml-4 {
    margin-left: 0.25rem;
  }
  .tooltip {
    position: relative;
    cursor: pointer;
  }
  .btn-toggle {
    background: rgba(241, 241, 241, 1);
    color: rgba(181, 181, 181, 1);
    padding: 4px 10px;
    border-radius: 8px;
  }
  .cancel-order-checkbox .Polaris-BlockStack {
    margin-left: 1em;
  }
`;
