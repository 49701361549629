/* eslint-disable @typescript-eslint/no-unused-vars */
import BoldText from '@/components/BoldText';
import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import ModalConfirm from '@/components/ModalConfirm';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, ListType } from '@/constants/enum';
import { checkShowErrorInline, formatDate, handleToastMutation, removeFalsyValues, validateUrl } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import blockCheckoutAccessSlice, {
  clearSetting,
  handleErrorRule,
  handleSetting,
  settingBackupSelector,
  settingSelector,
} from '@/redux/slice/blockCheckoutAccess';
import toastSlice from '@/redux/slice/toast.slice';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  Collapsible,
  InlineGrid,
  LegacyStack,
  Link,
  List,
  RadioButton,
  Select,
  Text,
  TextField,
} from '@shopify/polaris';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import useCondition from '../hooks/useCondition';
import useErrorRule from '../hooks/useErrorRule';
import { BlockPageStyled } from './styled';
import { config } from '@/config';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import useContextual from '@/components/ContextualBar/HideContextual';

const BlockCheckoutAccessPage = () => {
  const { state } = useLocation();
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contextual = useContextual();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [upsertRule, upsertRuleStatus] = apiCaller.useUpsertRuleMutation();
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();

  const [linkRedirectError, setLinkRedirectError] = useState('');
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const blockRuleSelected = useSelector(settingSelector);
  const blockRuleBackupSelected = useSelector(settingBackupSelector);
  const isShowContextualBar = useSelector(isShowSelector);

  const conditionHook = useCondition();
  const condition = conditionHook.condition.find((item) =>
    item.groupCriteria.includes(blockRuleSelected.criteria as CriteriaType),
  );
  const listCriteria = conditionHook.listCriteria.filter((item) => condition?.groupCriteria.includes(item.value));
  const criteriaSelected = listCriteria.find((item) => item.value === blockRuleSelected.criteria);
  const handleCountry = useCountry();
  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | boolean) => {
    dispatch(
      blockCheckoutAccessSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  useEffect(() => {
    if (!criteriaSelected) {
      navigate(PATH.BLOCKED_CHECKOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!criteriaSelected]);

  const handleDisabled = useMemo(() => {
    const fieldsRequired = condition?.fieldsRequired || [];

    const disabled = !!fieldsRequired.find((field) => {
      const value = blockRuleSelected[field as keyof typeof blockRuleSelected];

      if (blockRuleSelected.type === ActionType.Redirect) {
        return (
          (Array.isArray(value) && value.length === 0) ||
          !value ||
          !blockRuleSelected.linkRedirect ||
          (blockRuleSelected.linkRedirect && !validateUrl(blockRuleSelected.linkRedirect)) ||
          useError.listError.length > 0
        );
      }
      return (Array.isArray(value) && value.length === 0) || !value || useError.listError.length > 0;
    });

    const isNoChange = isEqual(blockRuleBackupSelected, blockRuleSelected);

    return disabled || isNoChange;
  }, [blockRuleBackupSelected, blockRuleSelected, condition?.fieldsRequired, useError.listError.length]);

  const handleSave = async () => {
    try {
      const { collectionName, productName, createdAt, lastUpdatedAt, pageTitle, isActive, ...data } = blockRuleSelected;
      const dataRemovedFalsyValues = removeFalsyValues(data);
      upsertRule({
        ...dataRemovedFalsyValues,
        type: blockRuleSelected.priority === ListType.BlackList ? ActionType.Block : ActionType.WhiteList,
        priority: blockRuleSelected.priority,
        criteria: blockRuleSelected.criteria as CriteriaType,
        isActive,
        pathName: 'checkout-page',
      }).then((res) => {
        if ('data' in res) {
          if (res.data.state === 1) {
            navigate(PATH.BLOCKED_CHECKOUT);
            dispatch(blockCheckoutAccessSlice.actions.clearSetting());
          } else {
            let error: string | React.ReactElement | undefined;
            if (res.data?.url) {
              error = (
                <Text as="span" variant="bodyMd" tone="critical">
                  Unable to add some new entries due to invalid format or existing values: See details{' '}
                  <Link url={res.data?.url}>here</Link>
                </Text>
              );
            } else {
              error = (
                <div>
                  {res.data?.failureRules?.map((item) => (
                    <p key={item.errValue}>
                      <b>{item.errValue}: </b>
                      {item.errMsg}. Try to add another {criteriaSelected?.fieldName}.
                    </p>
                  ))}
                </div>
              );
            }
            useError.handleErrorRule(error);
          }
        }
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = useCallback(() => {
    if (blockRuleSelected.id) {
      deleteItem({ id: blockRuleSelected.id, pathName: 'checkout-page' }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          navigate(PATH.BLOCKED_CHECKOUT);
        }
      });
    }
  }, [blockRuleSelected.id, deleteItem, dispatch, navigate]);

  const handleDiscard = () => {
    dispatch(handleSetting(blockRuleBackupSelected));
    dispatch(handleErrorRule([]));
  };

  const ruleSummary: Array<{
    title: string;
    value?: string;
    regular?: boolean;
    visible?: CriteriaType[];
  }> = useMemo(() => {
    // [title , value , regular,condition visible]
    return [
      {
        title: 'Status',
        value: blockRuleSelected.isActive ? 'Enabled' : 'Disabled',
        regular: true,
      },
      {
        title: 'Condition',
        value: criteriaSelected?.label,
      },
      {
        title: 'IP address blocking',
        value: blockRuleSelected.ipAddress,
        regular: false,
        visible: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
      },
      {
        title: 'Product name',
        value: blockRuleSelected.productName?.join(', '),
        regular: false,
        visible: [CriteriaType.Product],
      },
      {
        title: 'Collection name',
        value: blockRuleSelected.collectionName?.join(', '),
        regular: false,
        visible: [CriteriaType.Collection],
      },

      {
        title: 'Page',
        value: blockRuleSelected.pageTitle?.join(', '),
        regular: false,
        visible: [CriteriaType.SpecificPage],
      },

      {
        title: 'Country',
        value: blockRuleSelected.country?.map((item) => handleCountry.renderCountry(item)).join(', '),
        regular: false,
        visible: [
          CriteriaType.Collection,
          CriteriaType.Product,
          CriteriaType.Country,
          CriteriaType.Province,
          CriteriaType.ISP,
          CriteriaType.ReferralLink,
          CriteriaType.ReferralLink,
          CriteriaType.SpecificPage,
          CriteriaType.UserAgent,
        ],
      },

      {
        title: 'Province selected',
        value: blockRuleSelected.state?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: 'City selected',
        value: blockRuleSelected.city?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: 'Browser',
        value: blockRuleSelected.browserName?.join(', '),
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: 'Device',
        value: blockRuleSelected.deviceType,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: 'OS',
        value: blockRuleSelected.osName,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },

      {
        title: 'ISP',
        value: blockRuleSelected.ispName?.join(', '),
        regular: false,
        visible: [CriteriaType.ISP],
      },

      {
        title: 'Referral URL',
        value: blockRuleSelected.referUrl,
        regular: false,
        visible: [CriteriaType.ReferralLink],
      },

      {
        title: 'Created at',
        value: blockRuleSelected.createdAt ? formatDate(blockRuleSelected.createdAt / 1000, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },

      {
        title: 'Last updated at',
        value: blockRuleSelected.lastUpdatedAt ? formatDate(blockRuleSelected.lastUpdatedAt, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },
    ];
  }, [blockRuleSelected, criteriaSelected, handleCountry]);

  const handleBackAction = () => {
    if (isShowContextualBar && config.embedded === '1') {
      contextual.hide();
    } else {
      navigate(state?.prePath || PATH.BLOCKED_CHECKOUT);
      dispatch(clearSetting());
    }
  };

  return (
    <Layout
      layoutProps={{
        title: `${blockRuleSelected.id ? 'Edit ' : ''} Block access checkout ${condition?.label}`,
        backAction: {
          onAction: handleBackAction,
        },
        // primaryAction: <ButtonSupport />,
      }}
    >
      <BlockPageStyled>
        <ContextualBar
          delay={state?.prePath}
          loadingSave={upsertRuleStatus.isLoading}
          value={blockRuleSelected}
          disabledSave={handleDisabled}
          onSave={handleSave}
          disabledDiscard={isEqual(blockRuleSelected, blockRuleBackupSelected)}
          onDiscard={handleDiscard}
        />

        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
          <BlockStack gap="400">
            <Card>
              <div className="block-page-status">
                <BoldText>
                  Rule status{' '}
                  <Badge tone={blockRuleSelected.isActive ? 'success' : undefined}>
                    {!blockRuleSelected.isActive ? 'Disabled' : 'Enabled'}
                  </Badge>
                </BoldText>
                <Button
                  onClick={() => handleChange('isActive')(!blockRuleSelected.isActive)}
                  variant={blockRuleSelected.isActive ? undefined : 'primary'}
                >
                  {blockRuleSelected.isActive ? 'Disable' : 'Enable'}
                </Button>
              </div>
              <RegularText>Enable to activate rules, disable to turn them off.</RegularText>
            </Card>

            <Card>
              <BlockStack gap="400">
                <Select
                  label={<BoldText>Select type</BoldText>}
                  value={blockRuleSelected.priority}
                  options={[
                    {
                      label: 'Block',
                      value: ListType.BlackList,
                    },
                    {
                      label: 'Whitelist',
                      value: ListType.WhiteList,
                    },
                  ]}
                  onChange={handleChange('priority')}
                />

                <Collapsible
                  id=""
                  open={blockRuleSelected.priority === ListType.BlackList}
                  transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
                >
                  <TextField
                    label="Error message"
                    value={blockRuleSelected.errorMessage}
                    autoComplete=""
                    placeholder="Unfortunately, you won't be able to complete checkout"
                    maxLength={255}
                    showCharacterCount
                    onChange={handleChange('errorMessage')}
                  />
                </Collapsible>
              </BlockStack>
            </Card>

            <div className="block-page-content">
              <Card>
                {listCriteria.length > 1 ? (
                  <>
                    <BoldText>Select condition</BoldText>
                    <div className="mt-16">
                      <LegacyStack vertical>
                        {listCriteria.map((item) => {
                          return (
                            <RadioButton
                              disabled={!!blockRuleSelected.id}
                              key={item.value}
                              label={item.label}
                              checked={item.value === blockRuleSelected.criteria}
                              id={`block-page-${item.value}`}
                              name={`block-page-${item.value}`}
                              onChange={() => {
                                dispatch(blockCheckoutAccessSlice.actions.handleErrorRule([]));
                                dispatch(
                                  blockCheckoutAccessSlice.actions.handleSetting({
                                    ...blockRuleSelected,
                                    criteria: item.value,
                                    referUrl: '',
                                    shortReferUrl: '',
                                    collectionId: [],
                                    state: [],
                                    city: [],
                                    country: [],
                                    ipAddress: '',
                                    ispName: [],
                                    ispCode: [],
                                    productId: [],
                                    pageId: [],
                                    deviceType: '',
                                    osName: '',
                                    browserName: [],
                                  }),
                                );
                              }}
                            />
                          );
                        })}
                      </LegacyStack>
                    </div>
                  </>
                ) : (
                  <BoldText>Condition: {criteriaSelected?.label}</BoldText>
                )}

                <div className="mt-8">
                  {blockRuleSelected.type === ActionType.Redirect ? (
                    <>
                      <TextField
                        label="Redirect to"
                        value={blockRuleSelected.linkRedirect}
                        onChange={(value) => {
                          handleChange('linkRedirect')(value);
                          if (linkRedirectError) {
                            setLinkRedirectError('');
                          }
                        }}
                        autoComplete="off"
                        prefix="https://"
                        requiredIndicator
                        error={linkRedirectError}
                        onBlur={() => {
                          if (!blockRuleSelected.linkRedirect) {
                            setLinkRedirectError('Redirect link must be added ');
                          } else if (!validateUrl(blockRuleSelected.linkRedirect))
                            setLinkRedirectError('Please enter a valid URL');
                        }}
                      />
                      <div className="mt-8" />
                      <TextField
                        label="Displayed name (optional)"
                        maxLength={32}
                        value={blockRuleSelected.shortUrl}
                        onChange={handleChange('shortUrl')}
                        autoComplete="off"
                        helpText="This name is displayed instead of the full URL to save space."
                      />
                    </>
                  ) : null}
                  {condition && condition.component
                    ? condition?.component({
                        label: `Enter ${criteriaSelected?.label}`,
                        placeholder: criteriaSelected?.placeholder,
                      })
                    : ''}
                </div>
              </Card>
            </div>
          </BlockStack>

          <div className="rule-summary-container">
            <Card padding={'0'}>
              <div className="block-page-rule-summary">
                <BoldText>Rule summary</BoldText>
              </div>
              <div className="block-page-rule-summary-content">
                <List type="bullet">
                  {ruleSummary
                    .filter((item) => {
                      if (!item.visible) return true;
                      return item.visible.includes(blockRuleSelected.criteria as CriteriaType);
                    })
                    .map((item) => {
                      return (
                        <>
                          <List.Item>
                            {item.title}:{' '}
                            {!item.value ? (
                              <Text as="span" variant="bodyMd" tone="subdued">
                                None
                              </Text>
                            ) : item.regular ? (
                              item.value
                            ) : (
                              <b>{item.value}</b>
                            )}
                          </List.Item>
                        </>
                      );
                    })}
                </List>
              </div>
            </Card>
          </div>
        </InlineGrid>
        <div className="block-page-group-button">
          {blockRuleSelected.id ? (
            <Button
              onClick={() => {
                setIsOpenModalDelete(true);
              }}
              tone="critical"
              variant="primary"
            >
              Delete
            </Button>
          ) : null}
          {config.embedded !== '1' && (
            <Button disabled={handleDisabled} loading={upsertRuleStatus.isLoading} onClick={handleSave} variant="primary">
              Save
            </Button>
          )}
        </div>

        <ModalConfirm
          onClose={() => setIsOpenModalDelete(false)}
          isLoading={deleteItemStatus.isLoading}
          isOpen={isOpenModalDelete}
          title="Delete rule?"
          onConfirm={handleDelete}
          sectionText="If you delete the rule, you won't be able to revert it."
        />
      </BlockPageStyled>
    </Layout>
  );
};
export default memo(BlockCheckoutAccessPage);
