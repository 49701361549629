import { Badge, Card, Tabs } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import VisitorAnalyticsChart from './VisitorAnalytics';
import BlockedChart from './Blocked';
import RedirectChart from './Redirect';
import userPlans from '@/hooks/userPlans';
import { useDispatch, useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleTabTotalVisitors, tabTotalVisitorSelector } from '@/redux/slice/visitorAnalytics.slice';
import ScrollInToView from '@/components/ScrollInToView';
import { IDScrollIntoView } from '@/constants/enum';

const AnalyticsChart = () => {
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const dataSettings = useSelector(dataSettingsSelector);
  const tabTotalVisitor = useSelector(tabTotalVisitorSelector);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(handleTabTotalVisitors(selectedTabIndex)),
    [dispatch],
  );

  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor',
      component: <VisitorAnalyticsChart />,
    },
    {
      id: 'blocked',
      content: 'Blocked visitor',
      component: <BlockedChart />,
    },
    {
      id: 'redirect',
      content: 'Redirected visitor',
      component: <RedirectChart />,
    },
  ];
  return (
    <ScrollInToView idScrollToBlock={IDScrollIntoView.Allowed_block_visitor}>
      <Card padding={'100'}>
        <div className="card-chart-container">
          <div className='d-flex align-center'>
            <Tabs tabs={tabs} fitted selected={tabTotalVisitor} onSelect={handleTabChange} />
            {
              userPlanFree && !dataSettings?.settings.enableVisitorAnalytics &&
              <div className='badge-demo'>
                <Badge tone="enabled">Demo</Badge>
              </div>
            }
          </div>
          {tabs[tabTotalVisitor].component}
        </div>
      </Card>
    </ScrollInToView>
  );
};
export default memo(AnalyticsChart);
