import RegularText from '@/components/RegularText';
import Switch from '@/components/Switch';
import { config } from '@/config';
import { BlockCheckoutType } from '@/constants/enum';
import { checkShowErrorInline, formatCreatedAt, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockCheckoutSlice, { dataTableSelector } from '@/redux/slice/blockCheckout.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/api/response.api';
import { Button, EmptyState, IndexTable, Modal, Pagination, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const description = [
  {
    value: BlockCheckoutType.Email,
    label: 'Specific email',
  },
  {
    value: BlockCheckoutType.EmailDomain,
    label: 'Email contain',
  },
  {
    value: BlockCheckoutType.PhoneNumber,
    label: 'Phone number',
  },
  {
    value: BlockCheckoutType.Name,
    label: 'Name',
  },
  {
    value: BlockCheckoutType.Country,
    label: 'Country',
  },
  {
    value: BlockCheckoutType.Address,
    label: 'Address',
  },
  {
    value: BlockCheckoutType.ZipCode,
    label: 'Zip code',
  },
];

interface IProps {
  onEdit: (item: IResponseApi.IBlockCheckoutRule) => void;
  onGuide: () => void;
}

const TableBlockCheckout = ({ ...props }: IProps): JSX.Element => {
  const scope = useScope();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const dataTable = useSelector(dataTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data, isLoading } = apiCaller.useGetBlockCheckoutRulesQuery(
    {
      identifierId: config.shop || '',
      search: dataTable.search,
      criteria: dataTable.criteria,
      page: dataTable.page,
      perPage: Number(dataTable.perPage),
      sortDirection: dataTable.sort,
    },
    { skip: isSkip },
  );

  const [deleteRule, deleteRuleStatus] = apiCaller.useRemoveBlockCheckoutRulesMutation();
  const [updateStatus, updateStatusStatus] = apiCaller.useMutationStatusBlockCheckoutRulesMutation();

  const [modalDelete, setModalDelete] = useState({
    isOpen: false,
    id: 0,
  });

  const toggleRule = useCallback(
    (id: number) => (value: boolean) => {
      updateStatus({
        id,
        isActive: value,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    },
    [dispatch, updateStatus],
  );

  const handleDelete = () => {
    deleteRule(modalDelete.id).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        if (data && data.meta.totalResult % Number(dataTable.perPage) === 1) {
          dispatch(
            blockCheckoutSlice.actions.handleDataTable({
              ...dataTable,
              page: dataTable.page - 1 || 1,
            }),
          );
        }
      }
      setModalDelete({
        id: 0,
        isOpen: false,
      });
    });
  };

  const items = useMemo(() => {
    return data?.rules.map((checkoutItem) => {
      return {
        criteria: <RegularText>{description.find((item) => item.value === checkoutItem.criteria)?.label}</RegularText>,
        description: (
          <RegularText>
            {checkoutItem.criteria === BlockCheckoutType.Country
              ? handleCountry.renderCountry(checkoutItem.value)
              : checkoutItem.value}
          </RegularText>
        ),
        createdAt: <RegularText>{formatCreatedAt(checkoutItem?.createdAt || 0)}</RegularText>,
        lastUpdatedAt: <RegularText>{checkoutItem.lastUpdatedAt ? formatCreatedAt(checkoutItem.lastUpdatedAt) : ''}</RegularText>,
        action: (
          <div className={scope.isViewOnly ? 'btn-container disable' : 'btn-container'}>
            <div className="control-btn control-btn-toggle">
              <Switch
                onSwitch={(checked) => toggleRule(checkoutItem?.id || 0)(checked)}
                isActive={checkoutItem.isActive}
                isLoading={updateStatusStatus.isLoading || scope.isViewOnly}
              />
            </div>
            <div className="absolute d-flex">
              <div className="control-btn edit-btn">
                <Tooltip content="Edit">
                  <Button
                    icon={EditIcon}
                    onClick={() => props.onEdit(checkoutItem)}
                    variant="plain"
                    disabled={scope.isViewOnly}
                  />
                </Tooltip>
              </div>
              <div className="control-btn remove-btn">
                <Tooltip content="Delete">
                  <Button
                    icon={DeleteIcon}
                    onClick={() =>
                      setModalDelete({
                        isOpen: true,
                        id: checkoutItem.id || 0,
                      })
                    }
                    variant="plain"
                    disabled={scope.isViewOnly}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [data?.rules, handleCountry, props, scope.isViewOnly, toggleRule, updateStatusStatus.isLoading]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ criteria, description, createdAt, lastUpdatedAt, action }, index) => (
      <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell>{criteria}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  return (
    <div className="pd-16">
      <div className="table-block table-block-blacklist">
        <IndexTable
          emptyState={
            false ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <EmptyState
                heading="Take control of your checkout"
                // action={{
                //   content: 'Start with new rule',
                //   onAction: () => {
                //     props.onGuide();
                //     dispatch(blockCheckoutSlice.actions.handleTabs(1));
                //     window.scrollTo({
                //       top: 200,
                //       behavior: 'smooth',
                //     });
                //   },
                // }}
                secondaryAction={{
                  content: 'Learn more',
                  url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
                  target: '_blank',
                }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                <RegularText>
                  Set up your blacklist now to control checkout, prevent potential threats from placing orders on your store.
                </RegularText>
              </EmptyState>
            )
          }
          resourceName={resourceName}
          itemCount={items?.length || 0}
          headings={[
            { title: 'Criteria' },
            { title: 'Description' },
            { title: 'Created at' },
            { title: 'Last updated' },
            { title: 'Action' },
          ]}
          selectable={false}
        >
          {false ? <SkeletonBodyText lines={16} /> : rowMarkup}
        </IndexTable>
        <div className="mt-16 pb-16">
          {data && data?.meta.totalResult ? (
            <Pagination
              label={
                data?.meta.totalResult
                  ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                      data.meta.currentPage * Number(data.meta.perPage),
                      data?.meta.totalResult,
                    )} of ${data?.meta.totalResult} items`
                  : null
              }
              hasPrevious={!isLoading && data && data.meta.currentPage > 1}
              onPrevious={() => {
                if (data) {
                  dispatch(
                    blockCheckoutSlice.actions.handleDataTable({
                      ...dataTable,
                      page: data && data.meta.currentPage - 1,
                    }),
                  );
                }
              }}
              hasNext={!isLoading && data && data.meta.currentPage < Math.ceil(data.meta.totalResult / Number(data.meta.perPage))}
              onNext={() => {
                if (data) {
                  dispatch(
                    blockCheckoutSlice.actions.handleDataTable({
                      ...dataTable,
                      page: data.meta.currentPage + 1,
                    }),
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>
      <Modal
        open={modalDelete.isOpen}
        onClose={() =>
          setModalDelete({
            id: 0,
            isOpen: false,
          })
        }
        title={'Do you want to delete rule'}
        primaryAction={{
          destructive: true,
          content: 'Delete',
          onAction: handleDelete,
          loading: deleteRuleStatus.isLoading,
          disabled: scope.isViewOnly,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () =>
              setModalDelete({
                id: 0,
                isOpen: false,
              }),
          },
        ]}
      >
        <Modal.Section>
          <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default memo(TableBlockCheckout);
