import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

interface Props {
  isNewUserAndShopifyPlus: boolean;
  isOldUserAndShopifyPlus?: boolean;
}

export const AccountPlanStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .pricing-button-group {
    background-color: #e0dede;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    width: fit-content;
    .Polaris-Button--plain {
      margin: 0;
    }
  }

  .plan-table {
    margin: 1rem 0 2rem;
    width: ${(props) => (props.isNewUserAndShopifyPlus ? '80%' : props.isOldUserAndShopifyPlus ? '70vw' : 'initial')};

    .plan-table-item {
      display: flex;
      position: relative;
      flex-direction: column;
      min-height: 200px;
      padding: 1.6rem;
      color: #0b1d2e;
      height: 100%;
      position: relative;
      border: 1px solid #efefef;
      box-shadow: rgb(221 221 221) 0px 0px 6px 1px;
      transition: 0.25s ease-in-out;
      background-color: white;
      border-radius: 0.6rem;
      background-color: white;

      .pricing-tag {
        margin-left: auto;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      .free_plan_price {
        text-align: center;
        height: 120px;
        .Polaris-Text--root {
          margin-top: 20px;
        }
      }

      .remaining_plan_price {
        height: 120px;
      }

      .Polaris-InlineStack {
        margin: 10px 0;
        .pricing-plan {
          font-size: 40px;
          font-weight: 600;
          line-height: 40px;
        }
      }

      .pricing-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      .pricing-plan-strike {
        text-decoration: line-through;
        color: #e44d54;
      }

      .content-plan {
        margin-bottom: auto;
      }

      .Polaris-ExceptionList {
        .Polaris-ExceptionList__Item {
          margin-bottom: 14px;
          font-size: 14px;
        }
      }

      .plan-table-layout-content-btn {
        display: flex;
        justify-content: center;
        min-height: 40px;
      }
    }
  }

  /* Start Css Compare plan */
  .compare-plan-container {
    margin-top: 1rem;
    padding: 2rem;
    border: 1px solid #efefef;
    box-shadow: rgb(221 221 221) 0px 0px 6px 1px;
    transition: 0.6s;
    background-color: white;
    border-radius: 0.6rem;
    width: 100%;
    overflow-x: auto;
    position: relative;

    .compare-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
      min-width: 600px;

      .check.sticky-col {
        min-width: 150px;
      }

      th,
      td {
        border: none;
        padding: 0.6rem;
      }

      .check span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      .feature-group td {
        background-color: #e6f3e6;
        font-weight: bold;
        padding: 15px 10px;
      }
    }

    .compare-table tr:not(.feature-group):hover {
      background-color: #f5f5f5;
      transition: 0.2s ease;
    }

    .sticky-col {
      position: sticky;
      left: -38px;
      z-index: 1;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    .compare-table {
      width: auto;
    }
    .sticky-col {
      min-width: 200px;
      background-color: white;
    }
  }
  /* End Css Compare plan */

  .pricing-faqs-container {
    width: 100%;
    margin-top: 2rem;

    h4.Polaris-Text--root {
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    .plan-table {
      flex-direction: column-reverse;
      max-width: calc(100vw - 72px);
      align-items: center;
    }
  }
`;
