import { ListType } from '@/constants/enum';
import options, { perPageOptions } from '@/constants/options';
import blockCheckoutAccessSlice, { blackListTableSelector } from '@/redux/slice/blockCheckoutAccess';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

export const BlackListFilter = () => {
  const dispatch = useDispatch();
  const blackListTable = useSelector(blackListTableSelector);

  const filters = [
    {
      key: 'priority',
      label: 'Priority',
      filter: (
        <ChoiceList
          title="Priority"
          titleHidden
          choices={[
            { label: 'All', value: '' },
            { label: 'Block', value: ListType.BlackList },
            { label: 'Whitelist', value: ListType.WhiteList },
          ]}
          selected={[blackListTable?.priority || '']}
          onChange={(value) => {
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                priority: (value[0] as ListType) || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.criteriaFilters.filter((item) => !item.hiddenBlockAccess)]}
          selected={[blackListTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[blackListTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${options.criteriaFilters.find((option) => option.value === blackListTable.criteria)?.label || 'All'}`,
      hidden: !blackListTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + blackListTable.perPage,
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            priority: '',
            page: 1,
          }),
        ),
      key: 'priority',
      label: `Priority: ${
        blackListTable?.priority === ListType.BlackList
          ? 'Block'
          : blackListTable?.priority === ListType.WhiteList
          ? 'Whitelist'
          : 'All'
      } `,
      hidden: !blackListTable.priority,
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};
