import { Subscription, UserPlan } from '@/constants/enum';
import accountPageSlice, { subscriptionSelector } from '@/redux/slice/account.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { Button } from '@shopify/polaris';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface Pricing {
  id: number;
  plan: UserPlan;
  price: number;
  annuallyDiscount: number;
  tag: null | string;
  annuallyDiscountedPrice: number;
  campaignPrice: number | null;
  campaignEndsAt?: number;
}

interface GroupButtonSubscriptionProps {
  listPricing: Pricing[];
}

const GroupButtonSubscription: React.FC<GroupButtonSubscriptionProps> = ({ listPricing }) => {
  const { state } = useLocation();
  const flashSale = state?.flashSale;
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const selectedSubscription = useSelector(subscriptionSelector);
  const defaultSubScription = dataSettings?.settings.user.subscription;

  useEffect(() => {
    if (flashSale) {
      dispatch(accountPageSlice.actions.handleSubscription(Subscription.Yearly));
    } else {
      if (defaultSubScription) {
        dispatch(accountPageSlice.actions.handleSubscription(defaultSubScription));
      }
    }
  }, [defaultSubScription, dispatch, flashSale]);

  const maxDiscount = useMemo(() => {
    if (listPricing && listPricing.length > 0) {
      const discountArray = listPricing.map((item) => item.annuallyDiscount);
      return Math.max(...discountArray);
    }
    return 0;
  }, [listPricing]);

  return (
    <div className="pricing-button-group">
      <Button
        onClick={() => {
          dispatch(accountPageSlice.actions.handleSubscription(Subscription.Monthly));
        }}
        variant={selectedSubscription === Subscription.Monthly ? 'secondary' : 'monochromePlain'}
      >
        Monthly
      </Button>
      <span className="ml-8">
        <Button
          onClick={() => {
            dispatch(accountPageSlice.actions.handleSubscription(Subscription.Yearly));
          }}
          variant={selectedSubscription === Subscription.Yearly ? 'secondary' : 'monochromePlain'}
        >
          Yearly (save up to {String(maxDiscount)}%)
        </Button>
      </span>
    </div>
  );
};

export default GroupButtonSubscription;
