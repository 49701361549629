import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import ScrollInToView from '@/components/ScrollInToView';
import { Enum, PATH } from '@/constants';
import { ActionType, IDScrollIntoView, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation, validateUrl } from '@/helpers';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  BlockStack,
  Text,
  Button,
  Card,
  ChoiceList,
  Icon,
  Link,
  RangeSlider,
  TextField,
  Tooltip,
  DataTable,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Switch from '@/components/Switch';
import { botListSelector } from '@/redux/slice/settings.slice';
import { IResponseApi } from '@/types/api/response.api';
import SettingSwitch from '@/components/SettingSwitch';

const BlockerAndRedirector = () => {
  const navigate = useNavigate();
  const scope = useScope();
  const dispatch = useDispatch();

  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const botList = useSelector(botListSelector);

  const { shopifyPlanPlus, userPlanPremium } = userPlans();
  const [activeVpn, activeVpnStatus] = apiCaller.useActiveProxyVpnMutation();
  const [activeAutoBlock, autoBlockStatus] = apiCaller.useActiveAutoBlockMutation();
  const [activeTorBlock, torBlockStatus] = apiCaller.useActiveTorBlockMutation();
  const [botListUpdate] = apiCaller.useUpdateBotListMutation();

  const [buttonClick, setButtonClick] = useState('');
  const [allowPrivateRelayStatus, setAllowPrivateRelayStatus] = useState(dataSettings?.settings?.allowVpnFromPrivateRelay);
  const [rangeValue, setRangeValue] = useState(dataSettings?.settings.vpnRiskCore);
  const [selectedMode, setSelectedMode] = useState<string[]>([dataSettings?.settings?.typeRestrictionVpnProxy || '']);
  const [textFieldValue, setTextFieldValue] = useState(dataSettings?.settings?.linkRedirectVpnProxy || '');
  const [showRenderChildren, setShowRenderChildren] = useState(selectedMode.toString() === '2');

  const handleRangeSliderChange = useCallback((value: number) => setRangeValue(value), []);

  const handleActiveAutoBlock = useCallback(() => {
    const autoBlock: IParamsApi.IContentProtection = {
      autoBlock: !dataSettings?.settings.autoBlock,
    };
    activeAutoBlock(autoBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('2');
  }, [activeAutoBlock, dataSettings?.settings.autoBlock, dispatch]);

  const handleActiveTorBlock = useCallback(() => {
    const torBlock: IParamsApi.IContentProtection = {
      torBlock: !dataSettings?.settings.torBlock,
    };
    activeTorBlock(torBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('3');
  }, [activeTorBlock, dataSettings?.settings.torBlock, dispatch]);

  const handleChoiceListChange = useCallback((value: string[]) => {
    setSelectedMode(value);
    setShowRenderChildren(value.includes('2'));
  }, []);

  const handleTextFieldChange = useCallback((value: string) => setTextFieldValue(value), []);

  const handleDisableBtn = useCallback((): boolean => {
    if (disablePlan([UserPlan.FREE]) || scope.isViewOnly) return true;
    const caseUrl =
      (textFieldValue === dataSettings?.settings?.linkRedirectVpnProxy && rangeValue === dataSettings.settings.vpnRiskCore) ||
      !validateUrl(textFieldValue);
    const caseBlock = rangeValue === dataSettings?.settings.vpnRiskCore;
    const caseAllowPrivateStatus = allowPrivateRelayStatus === dataSettings?.settings.allowVpnFromPrivateRelay;
    return caseAllowPrivateStatus && selectedMode[0] === dataSettings?.settings?.typeRestrictionVpnProxy
      ? selectedMode[0] === ActionType.Block
        ? caseBlock
        : caseUrl
      : false;
  }, [
    allowPrivateRelayStatus,
    dataSettings?.settings.allowVpnFromPrivateRelay,
    dataSettings?.settings?.linkRedirectVpnProxy,
    dataSettings?.settings?.typeRestrictionVpnProxy,
    dataSettings?.settings.vpnRiskCore,
    rangeValue,
    scope.isViewOnly,
    selectedMode,
    textFieldValue,
  ]);

  const handleActiveVpn = useCallback(() => {
    setButtonClick('4');
    activeVpn({
      status: !dataSettings?.settings.activeVpnProxy,
      type: selectedMode.toString(),
      url: textFieldValue,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  }, [activeVpn, dataSettings?.settings.activeVpnProxy, dispatch, selectedMode, textFieldValue]);

  const handleSaveVpn = useCallback(() => {
    setButtonClick('1');
    activeVpn({
      status: dataSettings?.settings.activeVpnProxy || true,
      type: selectedMode[0],
      url: selectedMode[0] === ActionType.Redirect ? textFieldValue : undefined,
      riskCore: rangeValue,
      allowPrivateRelay: allowPrivateRelayStatus,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  }, [
    activeVpn,
    dataSettings?.settings.activeVpnProxy,
    dispatch,
    selectedMode,
    textFieldValue,
    rangeValue,
    allowPrivateRelayStatus,
  ]);

  const toggleRule = useCallback((status: boolean) => {
    setAllowPrivateRelayStatus(status);
  }, []);

  const handleICloudRelay = () => {
    window.open('https://docs.ipblocker.io/faq/general-faq');
  };

  const renderChildren = () => (
    <div style={{ marginTop: '8px' }}>
      <TextField
        label=""
        labelHidden
        onChange={handleTextFieldChange}
        value={textFieldValue}
        autoComplete="off"
        prefix={textFieldValue ? null : 'https://'}
      />
    </div>
  );

  const handleToggle = async (bot: IResponseApi.BotItem) => {
    const res = await botListUpdate({
      ids: [bot?.id],
      status: !bot?.isAllow,
    });

    const condition = checkShowErrorInline(res);
    if (!condition.status) {
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
    }
  };

  const rows = botList.map((bot, index) => [
    bot.botName,
    <a key={bot.id} href={bot.url} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
      {bot.url}
    </a>,
    <Switch key={index} onSwitch={(checked) => handleToggle(bot)} isActive={!!bot.isAllow} />,
  ]);

  return (
    <BlockStack gap="400">
      <ScrollInToView idScrollToBlock={IDScrollIntoView.Proxy_and_vpn_blocker}>
        <SettingSwitch
          trackAction="setting_proxy_vpn_blocker"
          title="Proxy and VPN blocker"
          minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
          settingToggleProps={{
            action: { onAction: handleActiveVpn, loading: (activeVpnStatus.isLoading || isLoading) && buttonClick === '4' },
            enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings?.settings.activeVpnProxy,
          }}
        >
          <RegularText>
            Turn on to automatically block visitors who use{' '}
            <Link external target="_blank" url="https://docs.ipblocker.io/getting-started/proxy-and-vpn-blocker">
              Proxy or VPN.
            </Link>
            {dataSettings?.settings.activeVpnProxy ? (
              <div>
                <div className="mt-16 mb-16">
                  <RangeSlider
                    output
                    label={
                      <>
                        <div className="d-flex setting-risk-score">
                          <RegularText>Risk score starts at</RegularText>
                          <div
                            className="pointer"
                            onClick={() =>
                              window.open('https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score', '_blank')
                            }
                          >
                            <Icon source={InfoIcon} tone="subdued" />
                          </div>
                        </div>
                      </>
                    }
                    helpText="You can block IP addresses using VPN/Proxy based on a risk score. If you don't set it, we will automatically block all  VPN/Proxy IPs."
                    value={rangeValue || 0}
                    suffix={
                      <p
                        style={{
                          minWidth: '24px',
                          textAlign: 'right',
                        }}
                      >
                        {rangeValue}
                      </p>
                    }
                    min={0}
                    max={100}
                    step={1}
                    onChange={handleRangeSliderChange}
                  />
                </div>
                <ChoiceList
                  title="Mode"
                  choices={[
                    { label: 'Block', value: '1' },
                    { label: 'Redirect to', value: '2' },
                  ]}
                  selected={selectedMode}
                  onChange={handleChoiceListChange}
                />
                <div className="mt-8">
                  <BlockStack gap="300">
                    <RegularText>
                      Allow{' '}
                      <Tooltip content="iCloud Private Relay only available on Safari browser">
                        <Link onClick={handleICloudRelay}>iCloud Private Relay</Link>
                      </Tooltip>
                    </RegularText>
                    <Switch
                      onSwitch={(checked) => toggleRule(checked)}
                      isActive={allowPrivateRelayStatus}
                      isLoading={activeVpnStatus.isLoading || scope.isViewOnly}
                    />
                  </BlockStack>
                </div>
                {showRenderChildren && renderChildren()}
                <div className="mt-8">
                  <Button
                    onClick={handleSaveVpn}
                    disabled={handleDisableBtn()}
                    variant="primary"
                    loading={(activeVpnStatus.isLoading || isLoading) && buttonClick === '1'}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : null}
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_block_visitors}>
        <SettingSwitch
          trackAction="settings-auto-block_visitor"
          title="Auto-block visitors"
          minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
          settingToggleProps={{
            action: { onAction: handleActiveAutoBlock, loading: (autoBlockStatus.isLoading || isLoading) && buttonClick === '2' },
            enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings?.settings.autoBlock,
          }}
        >
          Turn on to auto-block visitors even when they change their IP addresses but still use the same browser.
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Tor_blocker}>
        <SettingSwitch
          trackAction="setting_tor_block"
          title="Tor blocker"
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: { onAction: handleActiveTorBlock, loading: (torBlockStatus.isLoading || isLoading) && buttonClick === '3' },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings?.settings.torBlock,
          }}
        >
          <RegularText>
            Turn on to automatically block visitors who use{' '}
            <Link external url="https://docs.ipblocker.io/getting-started/tor-blocker">
              Tor
            </Link>
            .
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Allowed_bot_list}>
        <Card>
          <BlockStack gap="300">
            <Text as="h3" variant="headingMd">
              Allowed bot list
            </Text>
            <Text as="h6">
              These bots are allowed to access your store because they are trusted and do not pose a threat to your store.
            </Text>

            <DataTable
              columnContentTypes={['text', 'text', 'text']}
              headings={[
                <BoldText key="bot_name">Bot name</BoldText>,
                <BoldText key="resource">Resource</BoldText>,
                <BoldText key="action">Action</BoldText>,
              ]}
              rows={rows}
            />

            <Text as="p" tone="subdued">
              Is there a bot you want to allow access to your store?{' '}
              <Link onClick={() => navigate(PATH.FEEDBACK)}>Request to allow a new bot here.</Link>
            </Text>
          </BlockStack>
        </Card>
      </ScrollInToView>
    </BlockStack>
  );
};
export default memo(BlockerAndRedirector);
