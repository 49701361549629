import { config } from '@/config';
import contextualBarSlice, { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useDispatch, useSelector } from 'react-redux';

const useHandleContextualNotEmbeded = () => {
  const hide = () => {};
  return {
    hide,
  };
};

const useHandleContextual = () => {
  const shopify = useAppBridge();
  const isShowed = useSelector(isShowSelector);
  const dispatch = useDispatch();

  const hide = () => {
    if (isShowed) {
      shopify?.saveBar?.leaveConfirmation();
    } else {
      shopify?.saveBar?.hide('my-save-bar');
      dispatch(contextualBarSlice.actions.handleShow(false));
    }
  };

  return {
    hide,
  };
};

const useContextual = config.embedded === '1' ? useHandleContextual : useHandleContextualNotEmbeded;

export default useContextual;
