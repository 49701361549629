import { Enum, PATH } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { BlockStack, Button, Card, InlineGrid, InlineStack, ProgressBar, Text } from '@shopify/polaris';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const CardForeverFree = () => {
  const navigate = useNavigate();
  const { userPlanFree } = userPlans();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const maxLimitVisitor = dataSettings?.settings?.user?.numberVisitorLimit || 0;
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery(
    {
      type: Enum.ActionType.Block,
      priority: Enum.ListType.BlackList,
      criteria: Enum.CriteriaType.IpAddress,
    },
    { skip: isSkip },
  );
  const getVisitorQuota = apiCaller.useGetCheckQuotaQuery(undefined, { skip: !userPlanFree || isSkip });
  const totalVisitor = getVisitorQuota.data?.totalVisitor ?? 0;
  const totalRulesActivated = ruleBlockSummary?.data?.totalRulesActivated ?? 0;

  return userPlanFree ? (
    <Card>
      <InlineGrid gap={isMobile ? "300" : "2000"} columns={isMobile ? 1 : 3} alignItems="center">
        <BlockStack>
          <Text as="h3">App plan:</Text>
          <Text as="h3" variant="headingMd">
            Forever Free
          </Text>
        </BlockStack>
        <BlockStack gap="300">
          <ProgressBar
            size="small"
            progress={maxLimitRules > 0 ? Math.min((totalRulesActivated / maxLimitRules) * 100, 100) : 0}
            tone="primary"
          />
          <InlineStack align="space-between">
            <Text as="h3">Active rules</Text>
            <Text as="h3" variant="headingMd">
              {totalRulesActivated}/{maxLimitRules}
            </Text>
          </InlineStack>
        </BlockStack>
        <BlockStack gap="300">
          <ProgressBar
            size="small"
            progress={maxLimitRules > 0 ? Math.min((totalVisitor / maxLimitVisitor) * 100, 100) : 0}
            tone="primary"
          />
          <InlineStack align="space-between">
            <Text as="h3">Remaining visitor data</Text>

            <Text as="h3" variant="headingMd">
              {totalVisitor}/{maxLimitVisitor}
            </Text>
          </InlineStack>
        </BlockStack>
      </InlineGrid>

      <div className="mt-16">
        <InlineStack align="end">
          <Button variant="plain" onClick={() => navigate(PATH.PRICING_PLAN)}>
            Increase limitation
          </Button>
        </InlineStack>
      </div>
    </Card>
  ) : null;
};

export default CardForeverFree;
