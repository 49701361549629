import { config } from '@/config';
import { colors } from '@/constants/colors';
import styled from 'styled-components';

interface Props {
  isFullWidth?: boolean;
}

export const LayoutStyled = styled.div<Props>`
  max-width: 1902px;

  .Polaris-Page {
    width: 100%;
    max-width: ${(props) => (props.isFullWidth ? '100%' : '')};
  }

  .Polaris-Frame__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .Polaris-Page {
      .Polaris-Page--divider {
        min-height: calc(100vh - 112px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .Polaris-Page > .Polaris-Box {
      position: sticky !important;
      top: ${() => (config.role === 'admin' ? '28px' : 0)};
      z-index: 510;
      background-color: #f1f1f1;
      padding: 20px 0px;
    }
    .Polaris-Page > .Polaris-Box ~ .Polaris-Box {
      position: relative;
    }
  }
  .nav-bar {
    position: sticky;
    top: 0;
    z-index: 510;
    width: calc(100vw - 20px);
    margin-bottom: 14px;
  }
  .layout-footer {
    text-align: center;
    margin: 1rem 0;
  }
    .banner-refer-app {
    max-width: 950px;
    margin: 0 auto;
    .banner-refer-app-content {
      align-items: center;
      gap: 0.25em;
      .banner-refer-app-logo {
        border-radius: 8px;
        margin-right: 8px;
        width: 30px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      }
      .btn-set-up {
        width: 15%;
      }
    }
  }

  .btn-export {
    .Polaris-Button {
      background: ${colors.primary};
      color: #fff;
    }
    .Polaris-Button svg {
      fill: #fff;
    }
  }

  @media only screen and (max-width: 1024px) {
    .Polaris-Page {
      min-width: 0;
      max-width: calc(100vw - 8px);
    }
    .banner-refer-app {
      max-width: 904px;
    }
    .banner-refer-app-content {
      display: flex !important;
      flex-direction: row !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .Polaris-Page {
      min-width: 0;
      max-width: calc(100vw - 28px);
    }
  }
`;
