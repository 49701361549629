import { PATH } from '@/constants';
import { IDScrollIntoView } from '@/constants/enum';
import { handleIdScrollIntoView } from '@/redux/slice/home.slice';
import { Badge, BadgeProps } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type CustomBadgeProps = BadgeProps & {
  scrollToID: IDScrollIntoView;
};

const CustomBadge = (props: CustomBadgeProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    navigate(PATH.PRICING_PLAN);
    if (props?.scrollToID) dispatch(handleIdScrollIntoView(props.scrollToID));
  };

  return (
    <div className="pointer" onClick={handleOnClick}>
      <Badge {...props} tone={props.tone || 'info'}>
        {props.children}
      </Badge>
    </div>
  );
};
export default memo(CustomBadge);
