import styled from 'styled-components';

type Props = { isScaled: boolean };

export const ScrollInToViewStyled = styled.div<Props>`
  transform: ${({ isScaled }) => (isScaled ? 'scale(1.03)' : 'scale(1)')};
  transition: transform 0.3s ease-in-out;
  box-shadow: ${({ isScaled }) => (isScaled ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '')};
  border-radius: 12px;
`;
