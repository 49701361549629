import { Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { SwitchStyled } from './styled';

interface Props {
  isActive?: boolean;
  onSwitch: (checked: boolean) => void;
  isLoading?: boolean;
  hasTooltip?: boolean;
}

const Switch = ({ isLoading, hasTooltip = true, ...props }: Props) => {
  return (
    <SwitchStyled>
      {hasTooltip ? (
        <Tooltip content={props.isActive ? 'Turn off' : 'Turn on'}>
          <div
            className={`rule-toggle ${props.isActive && 'active'} ${isLoading ? 'disable' : ''}`}
            onClick={() => {
              if (!isLoading) props.onSwitch(!props.isActive);
            }}
          />
        </Tooltip>
      ) : (
        <div
          className={`rule-toggle ${props.isActive && 'active'} ${isLoading ? 'disable' : ''}`}
          onClick={() => {
            if (!isLoading) props.onSwitch(!props.isActive);
          }}
        />
      )}
    </SwitchStyled>
  );
};

export default memo(Switch);
