import styled, { keyframes } from 'styled-components';

interface Props {}

const luxuryFadeIn = keyframes`
  0% {
    opacity: 0.8;
    transform: scale(0.8) translateY(20px);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.04) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

export const ModalSearchStyled = styled.div<Props>`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
  animation: ${luxuryFadeIn} 0.3s;

  .card-content {
    width: 100%;
    max-width: 620px;
    max-height: 620px;
    height: auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 4px;
    margin: 2rem;

    .search-result {
      max-height: 550px;
      overflow-y: auto;
    }
  }
`;

export const RenderItemSearchStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 8px;
  border-radius: 4px;
  margin-bottom: 2px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    background: rgba(241, 242, 244, 1);
  }

  #text-highlight {
    color: blueviolet;
  }
`;
