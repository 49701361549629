import Layout from '@/components/layout';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Canny from './Canny';

const Feedback = () => {
  const [ssoToken, setSSOToken] = useState<string>('');
  const isSkip = useSelector(isSkipApiSelector);
  const { data } = apiCaller.useGetSSOCannyTokenQuery(undefined, { skip: isSkip });
  useEffect(() => {
    if (data) {
      setSSOToken(data.token);
    }
  }, [data]);

  return (
    <Layout
      layoutProps={{
        title: 'Feedback',
      }}
    >
      <Canny ssoToken={ssoToken} />
    </Layout>
  );
};

export default Feedback;
