import RenderRouter from '@/routes';
import { createApp } from '@shopify/app-bridge';
import { NavMenu } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { AppProvider, Frame, SkeletonPage } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from './asset';
import Toast from './components/common/Toast';
import SpecialOffer from './components/SpecialOffer';
import { config } from './config';
import customersList from './constants/customerHidePayment';
import { PATH } from './constants/path';
import UserPlans from './hooks/userPlans';
import { apiCaller } from './redux/query';
import slice from './redux/slice';
import authSlice, { isSkipApiSelector } from './redux/slice/auth.slice';
import { dataSettingsSelector } from './redux/slice/dataSettings.slice';
import { handleShowSpecialOffer, showSpecialOfferSelector } from './redux/slice/local.slice';
import welcomeSlice from './redux/slice/welcome.slice';

const navigationLinks: Array<{
  label: string;
  pathname: string;
  url: {
    pathname: string;
    search: string;
  };
}> = [
  {
    label: 'Visitor Analytics',
    pathname: PATH.ANALYTICS.pathname,
    url: PATH.ANALYTICS,
  },
  {
    label: 'Block & Re-director',
    pathname: PATH.BLOCKLIST.pathname,
    url: PATH.BLOCKLIST,
  },
  {
    label: 'Fraud orders analytics',
    pathname: PATH.FRAUD_ORDER.pathname,
    url: PATH.FRAUD_ORDER,
  },
  {
    label: 'Block checkout',
    pathname: PATH.BLOCKED_CHECKOUT.pathname,
    url: PATH.BLOCKED_CHECKOUT,
  },
  ...(customersList?.includes(config.shop)
    ? [
        {
          label: 'Checkout Rules',
          pathname: PATH.CHECKOUT_RULES.pathname,
          url: PATH.CHECKOUT_RULES,
        },
      ]
    : []),
  {
    label: 'Settings',
    pathname: PATH.SETTINGS.pathname,
    url: PATH.SETTINGS,
  },
  {
    label: 'Feedback',
    pathname: PATH.FEEDBACK.pathname,
    url: PATH.FEEDBACK,
  },
  {
    label: 'Pricing',
    pathname: PATH.PRICING_PLAN.pathname,
    url: PATH.PRICING_PLAN,
  },
];

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSkip = useSelector(isSkipApiSelector);
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery(undefined, { skip: isSkip });
  const dataSettings = useSelector(dataSettingsSelector);
  const [trackAction] = apiCaller.useTrackActionMutation();

  const configApp = {
    apiKey: process.env.REACT_APP_APP_ID || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_ENV !== 'dev',
  };

  if (isSkip) {
    const getToken = async () => {
      try {
        dispatch(authSlice.actions.handleLoading(true));
        const app = createApp(configApp);
        const sessionToken = await getSessionToken(app);
        dispatch(
          authSlice.actions.handleToken({
            token: sessionToken,
            expiredTime: Date.now() + 60 * 1000,
            isLoading: false,
          }),
        );
      } catch (err) {
        dispatch(authSlice.actions.handleLoading(false));
      }
    };
    getToken();
  }

  useEffect(() => {
    if (fetchDataSettings.data) {
      dispatch(
        slice.dataSetting.actions.handleSettings({
          settings: fetchDataSettings.data.settings,
          url: fetchDataSettings.data.url || '',
          summaryStats: fetchDataSettings.data.summaryStats,
          quoteAppInstalled: fetchDataSettings.data.quoteAppInstalled || false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.data]);

  useEffect(() => {
    if (dataSettings) {
      if (dataSettings.settings.displayWelcome) {
        dispatch(welcomeSlice.actions.handleChangeWelcomeStep(dataSettings.settings.welcomeStep));
        navigate(PATH.WELCOME);
      }

      if (!dataSettings.settings.displayWelcome && 'welcome' === location.pathname) {
        navigate(PATH.DEFAULT);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSettings?.settings.displayWelcome]);

  useEffect(() => {
    dispatch(slice.dataSetting.actions.handleLoading(fetchDataSettings.isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.isFetching]);

  const [showCampaignFromToDate, setShowCampaignFromToDate] = useState(false);

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(
      fetchDataSettings.data?.settings.user.lastInstalledDate || fetchDataSettings.data?.settings.user.installedDate,
    );

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowCampaignFromToDate(true);
    } else {
      setShowCampaignFromToDate(false);
    }
  }, [fetchDataSettings.data?.settings.user.installedDate, fetchDataSettings.data?.settings.user.lastInstalledDate]);

  const showSpecialOffer = useSelector(showSpecialOfferSelector);
  const { userPlanFree, userPlanEnterprise, shopifyPlanPlus, planAppliedList } = UserPlans();

  const isShowSpecialOffer =
    !userPlanEnterprise &&
    !shopifyPlanPlus &&
    showSpecialOffer &&
    showCampaignFromToDate &&
    location.pathname !== PATH.WELCOME.pathname &&
    !planAppliedList?.length;

  const businessName = fetchDataSettings.data?.settings.user.businessName;

  const renderSpecialOffer = (
    <SpecialOffer
      image={images.BlockifyFraudCountryBlocker}
      heading="You’ve Got Unlimited Blockify Top-tier Plans for $1"
      description={`Congratulations ${businessName}! ✨ To celebrate our 7th anniversary, we're offering the first 100 users
      1 month of our Blockify ${userPlanFree ? 'Premium & Enterprise' : 'Enterprise'} plan for just $1.`}
      labelPrimaryBtn="🎁 Unbox the gift now"
      labelSecondaryBtn="Maybe later"
      onClickPrimaryBtn={() => {
        dispatch(handleShowSpecialOffer(false));
        navigate(PATH.PRICING_PLAN);
        trackAction('home_banner_offer_1USD');
      }}
      onClickSecondaryBtn={() => dispatch(handleShowSpecialOffer(false))}
    />
  );

  return (
    <AppProvider i18n={enTranslations}>
      {config.embedded === '1' ? (
        <>
          <Frame>
            <Toast />
            {fetchDataSettings && fetchDataSettings.data?.settings.isShowDevelopmentPlanPage ? (
              <NavMenu>
                <a
                  href={PATH.CONTACT_TO_USE_DEVELOPMENT_STORE.pathname}
                  rel="home"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
                  }}
                >
                  Development plan
                </a>
              </NavMenu>
            ) : (
              <>
                <NavMenu>
                  <a
                    href={PATH.DEFAULT.pathname}
                    rel="home"
                    onClick={(e) => {
                      e.preventDefault();
                      if (fetchDataSettings && fetchDataSettings.data?.settings.displayWelcome) {
                        navigate(PATH.WELCOME);
                      } else {
                        navigate(PATH.DEFAULT);
                      }
                    }}
                  >
                    Home
                  </a>
                  {navigationLinks.map((item) => {
                    return (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        id={item.pathname}
                        key={item.pathname}
                        href={item.pathname}
                        onClick={(event) => {
                          event.preventDefault();
                          if (fetchDataSettings && fetchDataSettings.data?.settings.displayWelcome) {
                            navigate(PATH.WELCOME);
                          } else {
                            navigate(item.url);
                          }
                        }}
                      >
                        {item.label}
                      </a>
                    );
                  })}
                </NavMenu>
              </>
            )}
            {fetchDataSettings.isLoading ? <SkeletonPage /> : isShowSpecialOffer ? renderSpecialOffer : <RenderRouter />}
          </Frame>
        </>
      ) : (
        <RenderRouter />
      )}
    </AppProvider>
  );
}

export default App;
