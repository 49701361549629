import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Subscription } from '@/constants/enum';
// Define the initial state using that type
const initialState = {
  subscription: Subscription.Yearly,
};

const accountPageSlice = createSlice({
  name: 'account',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const subscriptionSelector = createSelector(
  (state: RootState) => state.account,
  (state) => state.subscription,
);

export default accountPageSlice;
